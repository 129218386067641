import { FunctionComponent, useCallback } from "react";
import Header from "../../components/User/Header";
import Footer from "../../components/User/UserFooter";
import FAQItem from "../../components/Merchant/FAQItem";

const MercahntHubFAQ: FunctionComponent = () => {
  const onItemClick = useCallback(() => {
    //logic here
  }, []);

  const onQuickAddContainerClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='background']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  return (
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start pb-0 box-border gap-[4px] tracking-[normal] text-center text-mini text-dark-navy font-subhead">
      <Header />
      <div className="min-h-screen flex pb-20 flex-col items-center justify-center my-10 w-full px-4">
        <h1 className="m-0 pt-8 px-2 text-black font-subhead font-semibold text-29xl:mq780 mq780:text-19xl">Frequently Asked Questions</h1>
        <p className="text-base font-subhead text-zinc-400 w-[90%] max-w-[500px] pb-16 pt-2 mx-4">Need more information? Here are some things we are commonly asked.</p>
        <div className="w-full max-w-3xl flex flex-col gap-8">
          <FAQItem
            question="Is DoYouWeekend free to use?"
            answer="Yes, DoYouWeekend is free for users. You only pay for the deals or experiences you choose to purchase through the platform."
          /> 
          <FAQItem
            question="How do I redeem a promotion or deal?"
            answer="After purchasing a deal, you’ll receive a unique QR code or confirmation number. Simply present this code at the participating business to redeem your offer."
          /> 
          <FAQItem
            question="What is DoYouWeekend?"
            answer="DoYouWeekend is a platform that helps you discover and book local activities, experiences, and deals based on your interests."
          /> 
          <FAQItem
            question="Can I change my preferences?"
            answer="Yes, you can update your preferences anytime in your profile settings, allowing us to refine your recommendations."
          /> 
          <FAQItem
            question="What is the refund policy?"
            answer="Refunds depend on the terms of each deal. Please review the terms before purchasing. If you have any issues, our support team is available to assist."
          /> 
        </div>
      </div>
      <footer className="mt-auto w-full"> {/* This will push the footer to the bottom */}
        <Footer
          dYWLogoW1="/dyw-logow-1@2x.png"
          propAlignSelf="stretch"
          propPosition="unset"
          propTop="unset"
          propLeft="unset"
          propWidth="unset"
        />
      </footer>  
    </div>
  );
};

export default MercahntHubFAQ;
