import React, { useState, useRef } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { X } from 'lucide-react';

interface ImageUploaderProps {
  onImageChange: (imageData: string | undefined) => void;
  maxSize?: number; // in bytes
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ onImageChange, maxSize = 15 * 1024 * 1024 }) => {
  const [image, setImage] = useState<string | undefined>();
  const [crop, setCrop] = useState<Crop>({ unit: '%', width: 100, height: 100, x: 0, y: 0 });
  const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);
  const [cropSaved, setCropSaved] = useState(false);
  const [imageSize, setImageSize] = useState<string>('');
  const [fileSizeError, setFileSizeError] = useState<string | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size > maxSize) {
        setFileSizeError("Image size must not exceed 15MB");
        return;
      }
      if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
        setFileSizeError("Image must be of type JPG, PNG, or GIF");
        return;
      }
      const reader = new FileReader();
      reader.onload = (event) => {
        setImage(event.target?.result as string);
        setImageSize(calculateImageSize(file));
        setFileSizeError(null);
        setCropSaved(false);
      };
      reader.readAsDataURL(file);
    }
  };

  const calculateImageSize = (file: File) => {
    const size = file.size / 1024; // Convert to KB
    if (size < 1024) {
      return `${size.toFixed(2)} KB`;
    } else {
      return `${(size / 1024).toFixed(2)} MB`;
    }
  };

  const getCroppedImg = (image: HTMLImageElement, crop: Crop): Promise<string> => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    if (ctx) {
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );
    }

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error('Canvas is empty'));
            return;
          }
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            resolve(reader.result as string);
          };
        },
        'image/jpeg',
        1
      );
    });
  };

  const handleCropSave = async () => {
    if (imgRef.current && completedCrop) {
      try {
        const croppedImageUrl = await getCroppedImg(imgRef.current, completedCrop);
        setImage(croppedImageUrl);
        onImageChange(croppedImageUrl);
        setCropSaved(true);
      } catch (e) {
        console.error('Error cropping image:', e);
      }
    }
  };

  const removeImage = () => {
    setImage(undefined);
    setImageSize('');
    setCropSaved(false);
    onImageChange(undefined);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      if (file.size > maxSize) {
        setFileSizeError("Image size must not exceed 15MB");
        return;
      }
      if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
        setFileSizeError("Image must be JPG, PNG, or GIF");
        return;
      }
      const reader = new FileReader();
      reader.onload = (event) => {
        setImage(event.target?.result as string);
        setImageSize(calculateImageSize(file));
        setFileSizeError(null);
        setCropSaved(false);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div
      style={{ maxWidth: 'min(624px, 90vw)', minWidth: '200px' }}
      className="flex justify-center items-center px-0 py-5 mt-9 w-inherit text-base leading-7 text-center rounded-md border border-solid border-zinc-800 text-zinc-800 max-md:px-5 max-md:max-w-full relative"
      onDragOver={(e) => e.preventDefault()}
      onDrop={handleDrop}
    >
      {image ? (
        <div className="w-full h-full relative">
          {!cropSaved ? (
            <>
              <ReactCrop
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={1}
              >
                <img ref={imgRef} src={image} alt="Upload preview" />
              </ReactCrop>
              <button onClick={handleCropSave} className="mt-2 bg-blue-500 text-white px-4 py-2 rounded">
                Save Crop
              </button>
            </>
          ) : (
            <img src={image} alt="Cropped preview" className="w-full h-auto" />
          )}
          <X
            onClick={removeImage}
            className="absolute top-2 right-2 bg-white rounded-full p-1 cursor-pointer"
          />
          {imageSize && (
            <div className="absolute bottom-2 left-2 bg-white rounded px-2 py-1 text-sm">
              {imageSize}
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col max-w-full w-[186px]">
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e6c7c97d7821c53ae0a76f061fb2c2ac41d67592fc162ea0f98664d14dd49d52?apiKey=0d607e805d95499aa95fa0a93ed5f083&"
            alt="Upload icon"
            className="self-center w-12 aspect-square"
          />
          <div className="mt-6">Drag & drop a photo here or click to upload</div>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="hidden"
            id="fileInput"
          />
          <label htmlFor="fileInput" className="mt-4 cursor-pointer text-blue-500 underline">
            Browse files
          </label>
        </div>
      )}
      {fileSizeError && <div className="text-red-500 text-sm mt-1">{fileSizeError}</div>}
    </div>
  );
};

export default ImageUploader;