import { FunctionComponent, useCallback, useState, useEffect } from "react";
import { ChevronDown, ChevronUp, X, Trash, Check } from "lucide-react";
import { createOrder, createCheckoutSession } from '../../api/stripe/stripeOrders';
import ItineraryItem from "../../components/User/ItineraryItem";
import ItineraryItemMobile from "../../components/Mobile/ItineraryItemMobile";
import Header from "../../components/User/Header";
import UserFooter from "../../components/User/UserFooter";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ConfirmationPopup from "../../components/Merchant/ConfirmationPopup";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

interface CreateItineraryModalProps {
  setShowCreateItineraryModal: (show: boolean) => void;
  onItineraryCreated: (newItinerary: Itinerary) => void;
}

interface Itinerary {
  id: string;
  customerId: string;
  name: string;
  offerIds: string[];
}

interface Currency {
  currencyCode: string;
  displayName: string;
  symbol: string;
  defaultFractionDigits: number;
  numericCode: number;
  numericCodeAsString: string;
}

interface Location {
  id: string;
  name: string;
  address: string;
  city: string;
  country: string;
  postalCode: string;
  region: string;
  latitude: number;
  longitude: number;
}

interface Category {
  id: string;
  name: string;
}

interface Highlight {
  id: string;
  name: string;
}

interface Time {
  hour: number;
  minute: number;
}

interface RecurrencePattern {
  startTime: Time;
  endTime: Time;
  daysOfWeek: string[];
  frequency: string;
  startDate: string;
  endDate: string;
}

interface Offer {
  id: string;
  merchantId: string;
  name: string;
  description: string;
  additionalInfo: string;
  currency: Currency;
  price: number;
  locations: Location[];
  categories: Category[];
  highlights: Highlight[];
  displayImageReferences: string[];
  stock: number;
  hasUnlimitedStock: boolean;
  recurrencePattern: RecurrencePattern;
  tags: string[];
  enabled: boolean;
  
  // Additional fields from the second structure
  categoryIds?: string[];
  highlightIds?: string[];
}

const CreateItineraryModal: React.FC<CreateItineraryModalProps> = ({ setShowCreateItineraryModal, onItineraryCreated }) => {
  const [itineraryName, setItineraryName] = useState("");
  const [creationSuccess, setCreationSuccess] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const createItinerary = async () => {
    try {
      const jwtToken = localStorage.getItem("jwtToken");
      if (!jwtToken) {
        throw new Error('No JWT token found');
      }

      const response = await axios.post(
        `${API_BASE_URL}/v1/itineraries`,
        { name: itineraryName },
        {
          headers: {
            'Authorization': `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 200 || response.status === 201) {
        setCreationSuccess(true);
        setTimeout(() => {
          setShowCreateItineraryModal(false);
          onItineraryCreated(response.data); // Pass the created itinerary data
        }, 200);
      }
    } catch (error) {
      console.error('Failed to create itinerary:', error);
      setError('Failed to create itinerary. Please try again.');
    }
  };

  return (
    <div className="font-subhead fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg">
        <h2 className="text-xl mb-4">Create Itinerary</h2>
        <div className="flex mb-4">
          <input
            type="text"
            value={itineraryName}
            onChange={(e) => setItineraryName(e.target.value)}
            placeholder="Enter itinerary name"
            className="flex-grow p-2 border rounded-l"
          />
          <button
            onClick={createItinerary}
            disabled={!itineraryName}
            className={`px-4 py-2 rounded-r ${
              itineraryName
                ? 'bg-main-teal text-white cursor-pointer'
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
          >
            Create
          </button>
        </div>
        {creationSuccess && (
          <p className="text-green-500 text-sm mb-4">Itinerary has been created</p>
        )}
        {error && (
          <p className="text-red-500 text-sm mb-4">{error}</p>
        )}
        <button
          onClick={() => setShowCreateItineraryModal(false)}
          className="text-zinc-500 pl-0 ml-0 bg-white cursor-pointer"
        >
          Close
        </button>
      </div>
    </div>
  );
};

const H6ItineraryPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { itineraryId } = useParams();

  const [isDropdownShown, setDropdownShown] = useState(false);
  const [sortOption, setSortOption] = useState('NYC Family Trip 2024');
  const [copied, setCopied] = useState(false);
  const [itineraries, setItineraries] = useState<Itinerary[]>([]);
  const [selectedItinerary, setSelectedItinerary] = useState<string>('Select Itinerary');
  const [offers, setOffers] = useState<Offer[]>([]);
  const [offerIds, setOfferIds] = useState<string[]>([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [itineraryToDelete, setItineraryToDelete] = useState<string | null>(null);
  const [showCreateItineraryModal, setShowCreateItineraryModal] = useState(false);

  useEffect(() => {
    if (!itineraryId && itineraries.length > 0) {
      navigate(`/itineraries/${itineraries[0].id}`);
    }
  }, [itineraryId, itineraries, navigate]);

  useEffect(() => {
    const fetchItineraries = async () => {
      try {
        const jwtToken = localStorage.getItem("jwtToken");
        if (!jwtToken) {
          throw new Error('No JWT token found');
        }
  
        const response = await fetch(`${API_BASE_URL}/v1/itineraries`, {
          headers: {
            'Authorization': `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
          }
        });
  
        if (!response.ok) {
          throw new Error('Failed to fetch itineraries');
        }
        const data: Itinerary[] = await response.json();
        setItineraries(data);
        
        // If there are itineraries and we're on the main itineraries page, redirect to the first one
        if (data.length > 0 && location.pathname === '/itineraries') {
          navigate(`/itineraries/${data[0].id}`);
        } else if (data.length > 0) {
          setSelectedItinerary(data[0].name);
        }
      } catch (error) {
        console.error('Error fetching itineraries:', error);
        // Handle the error appropriately, e.g., redirect to login page if token is invalid
      }
    };
  
    fetchItineraries();
  }, [navigate, location.pathname]);

  useEffect(() => {
    const fetchOffers = async () => {
      if (itineraryId) {
        try {
          const jwtToken = localStorage.getItem("jwtToken");
          if (!jwtToken) {
            throw new Error('No JWT token found');
          }
  
          const response = await fetch(`${API_BASE_URL}/v1/itineraries/${itineraryId}`, {
            headers: {
              'Authorization': `Bearer ${jwtToken}`,
              'Content-Type': 'application/json'
            }
          });
  
          if (!response.ok) {
            throw new Error('Failed to fetch offers');
          }
          const data = await response.json();
          // Check if the response is an array or a single object
          const fetchedOfferIds = Array.isArray(data) ? data : data.offerIds;
          setOfferIds(fetchedOfferIds);
  
          // Fetch full offer details for each offerId
          const offerDetails = await Promise.all(
            fetchedOfferIds.map(async (offerId: string) => {
              const offerResponse = await fetch(`${API_BASE_URL}/v1/catalog/offers/${offerId}`, {
                headers: {
                  'Authorization': `Bearer ${jwtToken}`,
                  'Content-Type': 'application/json'
                }
              });
              if (!offerResponse.ok) {
                throw new Error(`Failed to fetch offer details for offerId: ${offerId}`);
              }
              return offerResponse.json();
            })
          );
  
          setOffers(offerDetails);
        } catch (error) {
          console.error('Error fetching offers:', error);
          setOfferIds([]);
          setOffers([]);
        }
      } else {
        setOfferIds([]);
        setOffers([]);
      }
    };
  
    fetchOffers();
  }, [itineraryId]);

  const handleItineraryCreated = async (newItinerary: Itinerary) => {
    // Refresh the itineraries list
    const updatedItineraries = [...itineraries, newItinerary];
    setItineraries(updatedItineraries);
    
    // Select the new itinerary
    setSelectedItinerary(newItinerary.name);
    
    // Navigate to the new itinerary page
    navigate(`/itineraries/${newItinerary.id}`);
  };

  const handleItineraryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedItinerary(selectedValue);
    if (selectedValue === 'Select Itinerary' || selectedValue === 'Create Itinerary') {
      setOffers([]); // Clear offers
      if (selectedValue === 'Create Itinerary') {
        setShowCreateItineraryModal(true);
      }
    } else {
      const selectedItineraryObject = itineraries.find(itinerary => itinerary.name === selectedValue);
      if (selectedItineraryObject) {
        navigate(`/itineraries/${selectedItineraryObject.id}`);
      }
    }
  };

  const handleCreateItinerariesClick = () => {
    if (itineraries.length == 0) {setShowCreateItineraryModal(true);} 
  };

  const handleShareClick = (id: string) => {
    const url = `https://doyouweekend.com/itineraries/${id}`;
    
    if (itineraryId) {
      navigator.clipboard.writeText(url)
        .then(() => {
          // Optional: Show a success message to the user
          setCopied(true);
        })
        .catch((err) => {
          console.error('Failed to copy text: ', err);
          // Optional: Show an error message to the user
          alert("Failed to copy link. Please try again.");
        });
    }
  };

  const handleDeleteClick = (itineraryId: string) => {
    setItineraryToDelete(itineraryId);
    setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirm = async () => {
    if (itineraryToDelete) {
      try {
        const jwtToken = localStorage.getItem("jwtToken");
        if (!jwtToken) {
          throw new Error('No JWT token found');
        }

        const response = await fetch(`${API_BASE_URL}/v1/itineraries/${itineraryToDelete}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to delete itinerary');
        }

        // Remove the deleted itinerary from the state
        setItineraries(itineraries.filter(itinerary => itinerary.id !== itineraryToDelete));
        setSelectedItinerary('Select Itinerary');
        navigate("/itineraries")
        setShowDeleteConfirmation(false);
      } catch (error) {
        console.error('Error deleting itinerary:', error);
        // Handle the error appropriately
      }
    }
  };

  const removeOfferFromItinerary = async (offerId: string) => {
    if (itineraryId) {
      try {
        const jwtToken = localStorage.getItem("jwtToken");
        if (!jwtToken) {
          throw new Error('No JWT token found');
        }

        const response = await fetch(`${API_BASE_URL}/v1/itineraries/${itineraryId}/offers/${offerId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to remove offer from itinerary');
        }

        // Remove the offer from the local state
        setOffers(offers.filter(offer => offer.id !== offerId));
      } catch (error) {
        console.error('Error removing offer from itinerary:', error);
        // Handle the error appropriately
      }
    }
  };
  
  const handleBookNow = async (offerId: string) => {
    try {
      // Create the order
      const orderResponse = await createOrder(offerId, 1); // Assuming quantity 1 for simplicity
      if (orderResponse && orderResponse.id) {
        // Create the checkout session
        const checkoutSessionResponse = await createCheckoutSession(orderResponse.id);
        
        if (checkoutSessionResponse) {
          // Redirect to the checkout page
          navigate(`/checkout/${orderResponse.id}`);
        }
      }
    } catch (error) {
      console.error('Error during booking process:', error);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteConfirmation(false);
    setItineraryToDelete(null);
  };

  const handleSortOptionClick = (option: string) => {
    setSortOption(option);
    setDropdownShown(false);
  };

  const toggleDropdown = () => {
    setDropdownShown(!isDropdownShown);
  };

  const onButtonClick = useCallback(() => {
    // Please sync "H2.1 Search Results - map open" to the project
  }, []);

  const onMoreActivitiesClick = useCallback(() => {
    navigate("/map");
  }, []);

  const onDropdownClick = () => {
    setDropdownShown(!isDropdownShown);
  };

  return (
    <>
      <Header />
      {/* Desktop page (>=780px) */}
      <div className="hidden mq780:flex flex-col">
      <section className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-10 box-border max-w-full text-left text-61xl text-white font-subhead">
        <div className="flex-1 flex flex-row items-start justify-start relative max-w-full">
        <img
          className="w-full h-auto object-cover max-h-[35vh]"
          src="/family-on-beach.jpg"
          alt="Family on beach"
          loading="lazy"
        />
        <div className="absolute inset-0 bg-black bg-opacity-20" />
          <h1 onClick={() => navigate("/itineraries")} className="absolute bottom-4 left-20 text-61xl text-white font-bold z-10 -mb-0">
            My Itineraries
          </h1>
        </div>
      </section>
      <section className="pl-10 self-stretch flex flex-row items-start justify-between pt-0 pb-[39px] pr-7 box-border max-w-full text-left text-11xl text-gray-200 font-subhead">
        <div/>
          <div className="w-[88vw] relative">
          <select
            value={selectedItinerary}
            onClick={handleCreateItinerariesClick}
            onChange={handleItineraryChange}
            className="w-full pl-3 pr-10 py-3 bg-white font-subhead text-5xl text-black border border-solid border-zinc-600 rounded-lg appearance-none cursor-pointer"
          >
          {/* {itineraries.length > 0 &&
            <option value="Select Itinerary" disabled>Select Itinerary</option>
          } */}
            {itineraries.map((itinerary) => (
              <option key={itinerary.id} value={itinerary.name} className="text-black">
                {itinerary.name}
              </option>
            ))}
            <option value="Create Itinerary" className="text-blue-700">Create Itinerary</option>
          </select>
          <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 w-10 h-10 stroke-main-teal pointer-events-none" />
          
          {selectedItinerary !== 'Select Itinerary' && selectedItinerary !== 'Create Itinerary' && (
            <Trash
              className="absolute right-14 top-1/2 transform -translate-y-1/2 w-6 h-6 cursor-pointer stroke-main-teal"
              onClick={() => {
                const itineraryToDelete = itineraries.find(i => i.name === selectedItinerary);
                if (itineraryToDelete) {
                  handleDeleteClick(itineraryToDelete.id);
                }
              }}
            />
          )}
        </div>
        <button 
          onClick={() => handleShareClick(itineraryId || '')}
          className="cursor-pointer ml-5 p-1 bg-[transparent] rounded-lg flex flex-row items-start justify-start z-[2] border-[1px] border-solid border-main-teal"
        >
          <div className="rounded-lg flex flex-row items-start justify-start py-3 px-2">
            <b className="w-[114px] relative text-mini tracking-[-0.01em] leading-[19px] flex font-subhead text-main-teal text-center items-center justify-center min-w-[114px]">
              {copied ? "Link copied!" : "Share Itinerary"}
            </b>
          </div>
        </button>
        </section>
        {offers.length > 0 ? (
          offers.map((offer) => (
            <ItineraryItem
            key={offer.id}
            id={offer.id}
            image={offer.displayImageReferences[0]}
            title={offer.name}
            description={offer.description}
            onRemove={removeOfferFromItinerary}
            onBooking={() => handleBookNow(offer.id)}
          />
          ))
        ) : (
          <div className="flex flex-row mt-0 justify-center w-full">
            <p className="font-subhead text-3xl text-center max-w-[75vw] mt-[5vh]">No offers in this itinerary yet.</p>
          </div>  
        )}
      <div className="self-stretch flex flex-row items-start justify-center pt-10 pb-24 px-5 box-border max-w-full">
        <button
          className="cursor-pointer [border:none] pt-[19.3px] px-[42px] pb-[20.7px] bg-[transparent] flex flex-row items-start justify-start box-border relative whitespace-nowrap max-w-full mq450:pl-5 mq450:pr-5 mq450:box-border"
          onClick={onButtonClick}
        >
          <div className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-md bg-main-teal" />
          {itineraryId ? 
          <b
            onClick={onMoreActivitiesClick}
            className="h-[30px] w-[257px] relative text-6xl tracking-[-0.01em] flex font-subhead text-white text-center items-center justify-center z-[1]"
          >
            Add Activities
          </b>
          :
          <b
            // onClick={}
            className="h-[30px] w-[257px] relative text-6xl tracking-[-0.01em] flex font-subhead text-white text-center items-center justify-center z-[1]"
          >
            Select Itinerary
          </b>
          }
        </button>
      </div>
    </div>




    

      
      
      
      {/* Mobile page (<780px) */}
      <div className="mq780:hidden pt-[73px]">
        <section className="pb-30 self-stretch bg-white-100 flex flex-col items-start justify-start  relative max-w-full text-center text-29xl text-main-teal font-subhead min-h-[90vh]">
          <p onClick={() => navigate("/itineraries")} className="pl-6 bg-white text-start font-bold font-subhead m-0 py-4 pb-6 text-10xl">My Itineraries</p>
          <div className="flex flex-row gap-3 pl-5 pr-5 pb-6 w-[90vw]">
          <div className="flex-grow relative mt-0 pt-0 top-0">
            <select
              value={selectedItinerary}
              onChange={handleItineraryChange}
              onClick={handleCreateItinerariesClick}
              className={`w-full pl-3 ${itineraryId ? "pr-20" : "pr-12"} py-3 bg-white h-[56px] font-subhead text-xl mq480:text-5xl text-black border border-solid border-zinc-600 rounded-lg appearance-none cursor-pointer`}
            >
              {/* {itineraries.length > 0 &&
                <option value="Select Itinerary" disabled>Select Itinerary</option>
              } */}
              {itineraries.map((itinerary) => (
                <option key={itinerary.id} value={itinerary.name} className="text-black">
                  {itinerary.name}
                </option>
              ))}
              <option onClick={() => setShowCreateItineraryModal(true)} value="Create Itinerary" className="text-blue-700">Create Itinerary</option>
            </select>
            <ChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 w-9 h-9 stroke-main-teal pointer-events-none pl-2 mt-1" />
            
            {selectedItinerary !== 'Select Itinerary' && selectedItinerary !== 'Create Itinerary' && (
              <Trash
                className="absolute right-12 top-1/2 transform -translate-y-1/2 w-7 h-7 mt-1 cursor-pointer stroke-main-teal"
                onClick={() => {
                  const itineraryToDelete = itineraries.find(i => i.name === selectedItinerary);
                  if (itineraryToDelete) {
                    handleDeleteClick(itineraryToDelete.id);
                  }
                }}
              />
            )}
          </div>
          <button className="flex-shrink-0 mt-[11px] bg-transparent rounded-lg flex items-center justify-center border border-solid border-main-teal h-auto mb-[0px] h-[56px]"
            onClick={() => handleShareClick(itineraryId || '')}>
            <b className="w-[50px] text-mini tracking-[-0.01em] leading-[19px] font-subhead text-main-teal text-center py-[10px] mb px-2">
              {copied ? <Check className="w-8 h-8 mb-0 pb-0 mt-1"/> : "Share"}
            </b>
          </button>
        </div>
        {offers.length > 0 ? (
          offers.map((offer) => (
            <ItineraryItemMobile
              key={offer.id}
              id={offer.id}
              image={offer.displayImageReferences[0]}
              title={offer.name}
              description={offer.description}
              onRemove={removeOfferFromItinerary}
              onBooking={() => handleBookNow(offer.id)}
            />
          ))
        ) : (
          <div className="flex flex-col w-full">
            <div className="flex flex-row justify-center w-full">
              <p className="text-3xl text-center max-w-[75vw] mt-[10vh]">No offers in this itinerary yet.</p>
            </div>  
            <div className="flex flex-row justify-center w-full">
              <button
                onClick={onMoreActivitiesClick}
                className="h-[30px] w-[257px] relative text-xl tracking-[-0.01em] flex font-subhead text-white text-center items-center justify-center z-[1] bg-main-teal rounded-lg p-4 py-7 mt-6"
              >
                Add Activities
              </button>
            </div>  
          </div>
        )}
        </section>
      </div>
      <UserFooter
        dYWLogoW1="/dyw-logow-1@2x.png"
        propAlignSelf="stretch"
        propPosition="unset"
        propTop="unset"
        propLeft="unset"
        propWidth="unset"
      />
      {showDeleteConfirmation && (
        <ConfirmationPopup
          title={`Are you sure you want to Delete your Itinerary?`} //\ ${ItineraryItem.name}
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}
      {showCreateItineraryModal && (
        <CreateItineraryModal 
          setShowCreateItineraryModal={setShowCreateItineraryModal} 
          onItineraryCreated={handleItineraryCreated}
        />
      )}
    </>
  );
};

export default H6ItineraryPage;
