import { jwtDecode } from 'jwt-decode';

interface DecodedToken {
  sub: string;
  aud: "CUSTOMER" | "MERCHANT";  // Explicitly define the possible values
  iss: string;
  iat: number;
  exp: number;
  passwordVersion: number;
}

export const decodeToken = (token: string): DecodedToken | null => {
  try {
    const decoded = jwtDecode<DecodedToken>(token);
    return decoded;
  } catch (error) {
    console.error('Failed to decode token', error);
    return null;
  }
};

export const isTokenValid = (token: string): boolean => {
  const decoded = decodeToken(token);
  if (!decoded) return false;
  
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

export const getAudience = (token: string): "CUSTOMER" | "MERCHANT" | null => {
  const decoded = decodeToken(token);
  return decoded ? decoded.aud : null;
};