import { FunctionComponent, useCallback } from "react";
import MerchantHubHeader from "../../components/Merchant/MerchantHubHeader";
import Footer from "../../components/Merchant/MerchantFooter";
import FAQItem from "../../components/Merchant/FAQItem";

const MercahntHubFAQ: FunctionComponent = () => {
  const onItemClick = useCallback(() => {
    // Please sync "H4.1 Details Page - K1 Speed" to the project
  }, []);

  const onQuickAddContainerClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='background']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  return (
    <>
    <MerchantHubHeader selected="FAQ"/>
    <div className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start pb-0 box-border gap-[4px] tracking-[normal] text-center text-mini text-dark-navy font-subhead">
      <div className="min-h-screen flex pb-20 flex-col items-center justify-center my-10 w-full px-4">
        <h1 className="m-0 pt-8 px-5 text-black font-subhead font-semibold text-29xl:mq780 mq780:text-19xl">Frequently Asked Questions</h1>
        <p className="text-base font-subhead text-zinc-400 w-full max-w-[min(90vw,500px)] pb-16 pt-2 px-5">Need more information? Here are some things we are commonly asked.</p>
        <div className="w-full max-w-3xl flex flex-col gap-8">
          <FAQItem
            question="How and when do I get paid for the promotions I offer?"
            answer="You will receive payments directly to your bank account. Payments are processed weekly, ensuring you get your revenue promptly after customers redeem their promotions."
          /> 
          <FAQItem
            question="How is the fee structure of DoYouWeekend?"
            answer="DoYouWeekend charges a 15% commission on all bookings. For Chamber of Commerce members, the commission is reduced to 10%. Please note that there is an additional payment processing fee, which is beyond our control."
          /> 
          <FAQItem
            question="How will DoYouWeekend help me attract new customers?"
            answer="Doyouweekend uses advanced personalization algorithms to match your promotions with users who are genuinely interested in your offerings. We get to know our users, and match the ideal customer with your business"
          /> 
          <FAQItem
            question="What are the benefits of using DoYouWeekend compared to competitors?"
            answer="Unlike competitors, DoYouWeekend offers a low, transparent fee structure, allowing you to retain more revenue. Our advanced personalization algorithms ensure your promotions reach the right audience, boosting conversions and encouraging repeat visits."
          /> 
          <FAQItem
            question="What are the fees for listing my business on DoYouWeekend?"
            answer="DoYouWeekend charges a low, transparent fee for each promotion sold. There are no hidden costs or high commission rates, allowing you to maximize your revenue from promotions."
          /> 
          <FAQItem
            question="How does DoYouWeekend work?"
            answer="DoYouWeekend connects small businesses with customers through personalized promotions. Businesses list their promotions on our platform, and our advanced algorithms match these promotions with users who have shown interest in similar activities."
          /> 
          <FAQItem
            question="How secure is the platform for transactions?"
            answer="DoYouWeekend employs industry-standard security measures, including encryption and secure payment gateways, to ensure all transactions are safe and secure. Your business and customer data are protected at all times."
          /> 
          <FAQItem
            question="Can I customize the promotions and deals I offer?"
            answer=" Yes, you can fully customize your promotions and deals to suit your business needs and attract your target audience. Our platform allows you to set specific terms, conditions, and availability for each promotion."
          /> 
          <FAQItem
            question="How do customers redeem promotions from DoYouWeekend?"
            answer="Customers receive a QR code via email when they purchase a promotion. They can present this QR code at your business, which you can scan with your phone to redeem the promotion. This process ensures there is no fraud, and all transactions are accurately tracked."
          /> 
        </div>
      </div>
      <footer className="mt-auto w-full"> {/* This will push the footer to the bottom */}
        <Footer
          dYWLogoW1="/dyw-logow-1@2x.png"
          propAlignSelf="stretch"
          propPosition="unset"
          propTop="unset"
          propLeft="unset"
          propWidth="unset"
        />
      </footer>  
    </div>
    </>
  );
};

export default MercahntHubFAQ;
