import { FunctionComponent, useCallback } from "react";
import { useNavigate } from 'react-router-dom';

const QuizBlock: FunctionComponent = () => {
  const navigate = useNavigate();

  const onButtonStartQuizNowClick = useCallback(() => {
    navigate('/h71-quiz-page')
  }, []);

  const onButtonNoQuizClick = useCallback(() => {
    navigate('/h12-home-screen-no-quiz')
  }, []);

  return ( 
    <>  
    {/* For >= 1050px  */}
    <section className="hidden mq1050:flex px-[5%] flex flex-col items-start justify-start py-[40px] relative gap-[58px] bg-[url('/public/background@2x.png')] bg-cover bg-no-repeat bg-top z-1 text-left text-white font-subhead _mq750:gap-[29px_58px] _mq750:pt-[59px] _mq750:px-[25px] _mq750:pb-[79px] _mq1225:pl-[50px] _mq1225:pr-[50px]">
      <div className="flex flex-col items-start justify-start gap-[31px] max-w-full _mq750:gap-[15px_31px]">
        <h1 className="m-0 w-full relative text-41xl leading-[80px] font-bold tracking-tight _mq1050:text-[60px] _mq1050:leading-[60px] _mq750:text-[50px] _mq750:leading-[50px] _mq450:text-[40px] _mq450:leading-[40px]">
          Let Us Help You!
        </h1>
        <div className="flex flex-row gap-3">
          <h2 className="m-0 w-[70%] relative text-11xl leading-[50px] font-semibold tracking-[-0.01em] _mq1050:text-[30px] _mq1050:leading-[40px] _mq750:text-[25px] _mq750:leading-[35px] _mq450:text-[20px] _mq450:leading-[30px]">
            Find exclusive offers 
            <br/>nearby
          </h2>
          <button
            className="flex cursor-pointer border-none py-[15px] ml-[5vw] mt-6 pr-[73px] pl-[74px] bg-coral rounded-md flex items-center justify-center whitespace-nowrap z-2 hover:bg-chocolate _mq450:pl-5 _mq450:pr-5"
            onClick={() => navigate("/map")}
          >
            <b className="relative text-5xl font-bold text-white _mq450:text-[20px]">
              Let's Go!
            </b>
          </button>
        </div>
      </div>
    </section>

    {/* Mobile */}
    <section className="mq1050:hidden px-[5%] flex flex-col items-start justify-start py-[35px] mq480:py-[65px] relative gap-[58px] bg-[url('/public/background@2x.png')] bg-cover bg-no-repeat bg-top z-1 text-left text-white font-subhead">
      <div className="flex flex-col items-start justify-start gap-[31px] max-w-full _mq750:gap-[15px_31px]">
        <h1 className="m-0 w-full relative text-[80px] leading-[80px] font-bold tracking-tight _mq1050:text-[60px] _mq1050:leading-[60px] _mq750:text-[50px] _mq750:leading-[50px] _mq450:text-[40px] _mq450:leading-[40px]">
          Let Us Help You!
        </h1>
        <h2 className="m-0 w-[90%] mq480:w-[60%] relative text-[36px] leading-[50px] font-semibold tracking-[-0.01em] _mq1050:text-[30px] _mq1050:leading-[40px] _mq750:text-[25px] _mq750:leading-[35px] _mq450:text-[20px] _mq450:leading-[30px]">
          Find exclusive offers nearby
        </h2>
      </div>
      <button
        className="cursor-pointer border-none pt-[19.3px] pb-[20.7px] pr-[73px] pl-[74px] bg-coral rounded-md flex items-center justify-center whitespace-nowrap z-2 hover:bg-chocolate _mq450:pl-5 _mq450:pr-5"
        onClick={() => navigate("/map")}
      >
        <b className="relative text-[25px] font-bold text-white _mq450:text-[20px]">
          Let's Go!
        </b>
      </button>
    </section>
    </>  
  );
};

export default QuizBlock;