import { FunctionComponent, useMemo, useState, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { X } from "lucide-react";

export type ItineraryItemType = {
  id: string;  // Add this line
  title: string;
  description: string;
  image: string;
  dealpage?: string;
  onRemove: (offerId: string) => void;
  onBooking: () => void;  // Add this line
};

const ItineraryItem: FunctionComponent<ItineraryItemType> = ({
  id,  // Add this line
  title,
  description,
  image,
  dealpage="/",
  onRemove,
  onBooking,

}) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isHoursDropdownOpen, setIsHoursDropdownOpen] = useState(false);
    const [isContactDropdownOpen, setContactDropdownOpen] = useState(false);

    const navigate = useNavigate();

    const toggleHoursDropdown = () => {
        setIsHoursDropdownOpen(!isHoursDropdownOpen);
    };

    const toggleContactDropdown = () => {
        setContactDropdownOpen(!isContactDropdownOpen);
    };

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const onBookNowClick = () => {
        navigate(dealpage);
    }

    const truncateString = (str: string, num: number): string => {
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + '...';
    };

    const truncatedDescription = useMemo(() => truncateString(description, 240), [description]); //length of 240 fits all layouts

    const handleRemove = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        onRemove(id);
    };

    return (
        <section className="w-full h-[40vh] bg-zinc-50">
            <div className="flex flex-row gap-4 justify-start">
                <img 
                    src={image}
                    className="size-56 items-center justify-center p-5 ml-8"
                />
                <div className="flex flex-col max-w-[700px]">
                    <h1 className="font-subhead text-semibold text-black m-0 mb-1 mt-5">{title}</h1>
                    <p className="font-subhead text-base text-zinc-500 mt-1 leading-[28px]">{truncatedDescription}</p>
                    
                    <div className="flex flex-row gap-3 items-start">
                        <button 
                            className="w-[210px] cursor-pointer [border:none] py-[11px] px-2 bg-[transparent] flex flex-row items-center justify-center relative whitespace-nowrap z-[1]"
                            onClick={onBooking}
                        >
                            <div className="h-full w-full absolute !m-[0] top-[0px] right-[1px] bottom-[0px] left-[0px] rounded-md bg-white box-border border-[1px] border-solid border-main-teal" />
                            <b className="w-[167px] relative text-mini tracking-[-0.01em] leading-[19px] inline-block font-subhead text-main-teal hover:text-blue-800 text-center z-[1]">
                                Book Now
                            </b>
                        </button>
                        <button 
                            className="w-[210px] cursor-pointer [border:none] py-[11px] px-2 bg-[transparent] flex flex-row items-center justify-center relative whitespace-nowrap z-[1]"
                            onClick={handleRemove}
                        >
                            <div className="h-full w-full absolute !m-[0] top-[0px] right-[1px] bottom-[0px] left-[0px] rounded-md bg-white box-border border-[1px] border-solid border-main-teal" />
                            <b className="w-[167px] relative text-mini tracking-[-0.01em] leading-[19px] inline-block font-subhead text-main-teal hover:text-blue-800 text-center z-[1]">
                                Remove
                            </b>
                        </button>
                    </div>
                </div>
                <div className="hidden lg:flex w-[248px] my-5 text-subhead font-subhead flex flex-col items-center justify-start pt-0.5 px-0 pb-0 box-border min-w-[248px] text-xs mq800:flex-1">
                    <div className="hidden self-stretch flex flex-col items-start justify-start gap-[19px]">
                        <div onClick={toggleHoursDropdown} className="hover:text-blue-800 cursor-pointer relative tracking-[-0.01em] leading-[18px] font-medium z-[1]">
                            Hours & Directions
                        </div>
                        {isHoursDropdownOpen && (
                          <div
                            className="py-2 w-inherit pb-0 whitespace-nowrap cursor-pointer text-sm hover:bg-gray-100"
                          >
                            Today: 9:00AM - 10:00PM
                          </div>
                        )}
                        <div className="self-stretch h-px relative box-border z-[1] border-t-[1px] border-solid border-main-teal" />
                        <div onClick={toggleContactDropdown} className="hover:text-blue-800 cursor-pointer relative tracking-[-0.01em] font-medium inline-block min-w-[86px] z-[1]">
                            Contact Info
                        </div>
                        {isContactDropdownOpen && (
                          <div
                            className="py-2 w-inherit pb-0 whitespace-nowrap cursor-pointer text-sm hover:bg-gray-100"
                          >
                            Email: someEmail@gmail.com<br />Tel: +1 173 405 124
                        </div>
                        )}
                        <div className="self-stretch h-px relative box-border z-[1] border-t-[1px] border-solid border-main-teal" />
                        <b className="hover:text-blue-800 cursor-pointer relative [text-decoration:underline] text-main-teal z-[1]">
                            Remove from Itinerary
                        </b>
                    </div>
                </div>
                <div />
            </div>
            <div className="self-stretch h-1 box-border mt-7 shrink-0 border-t-[1px] border-solid border-silver-100" />
            {isPopupOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="relative bg-white p-8 rounded-lg max-w-md w-full font-subhead items-center justify-center">
                        <X className="cursor-pointer absolute top-3 right-3 w-7 h-7 stroke-zinc-700 font-light" onClick={togglePopup} />
                        <h2 className="text-19xl font-light mb-10 text-center justify-center">Select an Itinerary</h2>
                        <ul>
                            <li className="mb-2 list-none">
                                <input type="checkbox" id="itinerary1" name="itinerary1" />
                                <label htmlFor="itinerary1" className="ml-2 font-semibold">NYC Family Trip 2023</label>
                            </li>
                            <li className="mb-2 list-none">
                                <input type="checkbox" id="itinerary2" name="itinerary2" />
                                <label htmlFor="itinerary2" className="ml-2 font-semibold">South Carolina Visiting Grandma</label>
                            </li>
                            <li className="mb-2 list-none">
                                <input type="checkbox" id="itinerary3" name="itinerary3" />
                                <label htmlFor="itinerary3" className="ml-2 font-semibold">Philadelphia Family Trip</label>
                            </li>
                        </ul>
                        <div className="flex items-center justify-center flex-col">
                            <div className="bg-zinc-400 w-80 h-[1px] my-8" />
                            <button className="cursor-pointer hover:bg-blue-800 mt-0 px-auto w-64 font-subhead font-semibold bg-main-teal text-white py-2 rounded">Add a New Itinerary</button>
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
};

export default ItineraryItem;
