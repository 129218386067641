import { FunctionComponent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../../../components/User/Header";
import UserFooter from "../../../components/User/UserFooter";
import { ArrowLeft, ChevronDown, ChevronUp } from "lucide-react";
import AppleToggle from "../../../components/General/AppleToggle";
import SideNavUserProfile from "../../../components/User/SideNavUserProfile";
import EditButton from "../../../components/General/EditButton";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

interface Category {
  name: string;
  id: string;
}

interface CustomerProfile {
  firstName: string;
  lastName: string;
  email: string;
  id: string;
  status: string;
  userType: string;
  createdAt: string;
  updatedAt: string;
  birthdate: string;
  gender: string;
  interestedCategories: Category[];
}

interface InterestCategoriesProps {
  categories: Category[];
}

const InterestCategories: React.FC<InterestCategoriesProps> = ({ categories }) => {
  return (
    <div className="flex flex-wrap gap-4 w-inherit bg-white">
      {categories.map(category => (
        <button
          key={category.id}
          type="button"
          className="px-4 py-2 rounded-full border bg-blue-100 text-blue-800 border-blue-300"
        >
          {category.name}
        </button>
      ))}
    </div>
  );
};

interface PreferenceSettingProps {
  title: string;
  text: string;
}

const PreferenceSetting: React.FC<PreferenceSettingProps> = ({
  title,
  text,
}) => {
  return (
    <>
      <div className="flex flex-row px-5 mq780:px-0 mt-10 mq780:mt-2 justify-between w-[90%]">
        <div className="flex flex-col gap-1">
          <h2 className="mt-1 mb-0 text-lg mq780:text-base mq480:text-2xl">{title}</h2>
          <p className="max-w-[70vw] mq780:my-0">{text}</p>
        </div>
        <div className="mq780:mt-2 mq780:pl-4">
          <AppleToggle initialState={false}/>
        </div>
      </div>
      <div className="h-[1px] my-4 bg-zinc-200 w-full"/>
    </>
  );
};

interface CategorySelectorProps {
  categories: Category[];
  selectedCategories: Category[];
  onCategoryChange: (category: Category) => void;
  categoryError: string | null;
}

const CategorySelector: React.FC<CategorySelectorProps> = ({ 
  categories, 
  selectedCategories, 
  onCategoryChange,
  categoryError 
}) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="border border-solid rounded-lg w-[98%] border-zinc-400">
      {isOpen && (
        <div className="-mt-1 border rounded p-2 items-start justify-start">
          {categories.map(category => (
            <label key={category.id} className="w-auto block items-start justify-start my-1 text-lg">
              <input 
                type="checkbox"
                className="cursor-pointer"
                checked={selectedCategories.some(c => c.id === category.id)}
                onChange={() => onCategoryChange(category)}
              />
              <span className="ml-2">{category.name}</span>
            </label>
          ))}
          {categoryError && <p className="text-red-500 text-lg mt-2">{categoryError}</p>}
        </div>
      )}
    </div>
  );
};

const UserSettings: FunctionComponent = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState<CustomerProfile | null>(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);
  const [emailVerificationSent, setEmailVerificationSent] = useState(false);
  const [showEmailVerificationModal, setShowEmailVerificationModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [categoryError, setCategoryError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/v1/catalog/categories`);
        setCategories(response.data);
      } catch (error) {
        console.error('Failed to fetch categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleEmailVerification = () => {
    setShowEmailVerificationModal(true);
  };

  const sendEmailVerificationRequest = async () => {
    if (!profile) {
      setError('Profile data is not available');
      return;
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/v1/users/auth/email-confirmation/request`,
        { email: profile.email }
      );

      if (response.status === 200 || response.status === 204) {
        setEmailVerificationSent(true);
        // Automatically close the modal after 5 seconds
        setTimeout(() => setShowEmailVerificationModal(false), 5000);
      }
    } catch (error) {
      console.error('Failed to send email verification:', error);
      setError('Failed to send email verification. Please try again.');
    }
  };

  const EmailVerificationModal = () => {
    const [verificationEmail, setVerificationEmail] = useState(profile?.email || "");
    const [verificationSuccess, setVerificationSuccess] = useState(false);
  
    const sendEmailVerificationRequest = async () => {
      try {
        const response = await axios.post(
          `${API_BASE_URL}/v1/users/auth/email-confirmation/request`,
          { email: verificationEmail }
        );
  
        if (response.status === 200 || response.status === 204) {
          setVerificationSuccess(true);
          setEmailVerificationSent(true);
          // Automatically close the modal after 5 seconds
          setTimeout(() => setShowEmailVerificationModal(false), 200);
        }
      } catch (error) {
        console.error('Failed to send email verification:', error);
        setError('Failed to send email verification. Please try again.');
      }
    };
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="bg-white p-6 rounded-lg">
          <h2 className="text-xl mb-4">Verify Email</h2>
          <div className="flex mb-4">
            <input
              type="email"
              onChange={(e) => setVerificationEmail(e.target.value)}
              placeholder={"Enter your email"}
              className="flex-grow p-2 border rounded-l"
            />
            <button
              onClick={sendEmailVerificationRequest}
              disabled={!verificationEmail}
              className={`px-4 py-2 rounded-r ${
                verificationEmail
                  ? 'bg-main-teal text-white cursor-pointer'
                  : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              }`}
            >
              Send
            </button>
          </div>
          {verificationSuccess && (
            <p className="text-green text-sm mb-4">Verification Email sent!</p>
          )}
          <button
            onClick={() => setShowEmailVerificationModal(false)}
            className="text-zinc-500 pl-0 ml-0 bg-white cursor-pointer"
          >
            Close
          </button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem('jwtToken');

        if (!token) {
          setError('No authentication token found. Please log in.');
          console.error('No authentication token found.')
          setLoading(false);
          return;
        }

        const response = await axios.get<CustomerProfile>(`${API_BASE_URL}/v1/users/customer/profile`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setProfile(response.data);
        setLoading(false);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status === 401) {
          setError('Your session has expired. Please log in again.');
        } else {
          setError('Failed to fetch profile data');
        }
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!editMode || !hasChanges || !profile) return;
  
    if (profile.interestedCategories.length === 0) {
      setCategoryError("You have to select at least one category");
      return;
    }
  
    try {
      const payload = {
        firstName: profile.firstName,
        lastName: profile.lastName,
        birthdate: profile.birthdate,
        gender: profile.gender,
        interestedCategoryIds: profile.interestedCategories.map(category => category.id)
      };

    const token = localStorage.getItem('jwtToken');
    if (!token) {
      setError('No authentication token found. Please log in.');
      return;
    }

    const response = await axios.put(`${API_BASE_URL}/v1/users/customer/profile`, payload, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (response.status === 200) {
      setEditMode(false);
      setHasChanges(false);
      setCategoryError(null); // Clear any category error
      // console.log('Profile updated successfully');
    }
  } catch (error) {
    console.error('Failed to update profile:', error);
    setError('Failed to update profile. Please try again.');
  }
};

  const handlePasswordReset = () => {
    setShowResetModal(true);
  };

  const sendPasswordResetRequest = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/v1/users/auth/password-reset/request`,
        { email: resetEmail }
      );

      if (response.status === 200) {
        // console.log('Password reset request sent successfully');
        setShowResetModal(false);
        // Optionally, show a success message
      }
    } catch (error) {
      console.error('Failed to request password reset:', error);
      setError('Failed to request password reset. Please try again.');
    }
  };

  const ResetPasswordModal = () => {
    const [resetSuccess, setResetSuccess] = useState(false);
  
    const sendPasswordResetRequest = async () => {
      try {
        const response = await axios.post(
          `${API_BASE_URL}/v1/users/auth/password-reset/request`,
          { email: resetEmail }
        );
  
        if (response.status === 200 || response.status === 204) {
          setResetSuccess(true);
          // console.log('Password reset request sent successfully');
          // Automatically close the modal after 5 seconds
          setTimeout(() => setShowResetModal(false), 5000);
        }
      } catch (error) {
        console.error('Failed to request password reset:', error);
        setError('Failed to request password reset. Please try again.');
      }
    };
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="bg-white p-6 rounded-lg">
          <h2 className="text-xl mb-4">Reset Password</h2>
          <div className="flex mb-4">
            <input
              type="email"
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
              placeholder="Enter your email"
              className="flex-grow p-2 border rounded-l"
            />
            <button
              onClick={sendPasswordResetRequest}
              disabled={!resetEmail}
              className={`px-4 py-2 rounded-r ${
                resetEmail
                  ? 'bg-main-teal text-white cursor-pointer'
                  : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              }`}
            >
              Send
            </button>
          </div>
          {resetSuccess && (
            <p className="text-green text-sm mb-4">Password Reset Email sent!</p>
          )}
          <button
            onClick={() => setShowResetModal(false)}
            className="text-zinc-500 pl-0 ml-0 bg-white cursor-pointer"
          >
            Close
          </button>
        </div>
      </div>
    );
  };

  const handleBackClick = () => {
    navigate("/account");
  };

  const handleEditClick = () => {
    setEditMode(!editMode);
  };

  const renderContent = () => {
    if (loading) return <p>Loading profile...</p>;
    if (error) return <p>{error}</p>;
    if (!profile) return null;

    return (
      <>
        {/* Desktop Content */}
        <div className="hidden mq780:block bg-white p-8 rounded-lg font-subhead">
          <div className="flex flex-row justify-between w-[50vw] h-auto">
            <div className="flex items-center mb-8">
              <img 
                src="../Avatar_Default@4x.png"
                alt="Profile"
                className="w-24 h-24 rounded-full mr-6"
              />
              <div>
                <h2 className="text-2xl font-semibold text-gray-800">{`${profile.firstName} ${profile.lastName}`}</h2>
                <p className="text-gray-600 mb-1">Joined {new Date(profile.createdAt).toLocaleDateString('en-US', { month: 'short', year: 'numeric' })}</p>
                <div className="flex flex-row gap-3">
                  <p className={`text-gray-600 mt-0 pt-0 ${profile.status == "VERIFIED" ? "text-green" : ""}`}>{profile.status}</p>
                  {profile.status === "UNVERIFIED" && !emailVerificationSent ? (
                    <p onClick={handleEmailVerification} className="cursor-pointer text-[#478abf] mx-0 mt-0 p-0">
                      (Verify Now)
                    </p>
                  ) : null}
                  {profile.status === "UNVERIFIED" && emailVerificationSent ? (
                    <p className="text-green mt-0 font-semibold">Verification Email sent!</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div onClick={handleEditClick} className="w-auto h-[30px] mr-5 mt-6">
              <EditButton/>
            </div>
          </div>  
          <form onSubmit={handleSubmit} className="space-y-6 w-[50vw]">
            <div className="flex flex-row justify-between gap-4">
              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700 mb-1">First Name</label>
                <input 
                  type="text" 
                  className={`w-[90%] p-2 border rounded ${!editMode ? 'bg-gray-100' : ''}`}
                  value={profile.firstName} 
                  readOnly={!editMode}
                  onChange={(e) => {
                    if (editMode) {
                      setProfile(prev => prev ? { ...prev, firstName: e.target.value } : null);
                      setHasChanges(true);
                    }
                  }}
                />
              </div>
              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700 mb-1">Last Name</label>
                <input 
                  type="text" 
                  className={`w-[90%] p-2 border rounded ${!editMode ? 'bg-gray-100' : ''}`}
                  value={profile.lastName} 
                  readOnly={!editMode}
                  onChange={(e) => {
                    if (editMode) {
                      setProfile(prev => prev ? { ...prev, lastName: e.target.value } : null);
                      setHasChanges(true);
                    }
                  }}
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
              <input 
                type="text" 
                className={`w-[95%] p-2 border rounded ${!editMode ? 'bg-gray-100' : ''}`}
                value={profile.email} 
                readOnly={!editMode}
                onChange={(e) => {
                  if (editMode) {
                    setProfile(prev => prev ? { ...prev, email: e.target.value } : null);
                    setHasChanges(true);
                  }
                }}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Interest Categories</label>
              {editMode ? (
                <CategorySelector 
                categories={categories}
                selectedCategories={profile.interestedCategories}
                categoryError={categoryError}
                onCategoryChange={(category) => {
                  setProfile(prev => {
                    if (prev) {
                      const newCategories = prev.interestedCategories.some(c => c.id === category.id)
                        ? prev.interestedCategories.filter(c => c.id !== category.id)
                        : [...prev.interestedCategories, category];
                      return { ...prev, interestedCategories: newCategories };
                    }
                    return prev;
                  });
                  setHasChanges(true);
                  if (profile.interestedCategories.length === 1 && profile.interestedCategories[0].id === category.id) {
                    setCategoryError("You have to select at least one category");
                  } else {
                    setCategoryError(null);
                  }
                }}
              />
              ) : (
                <InterestCategories categories={profile.interestedCategories} />
              )}
            </div>
            <div className="mt-5 pt-8 flex flex-row w-full items-center justify-center">
            <button 
              onClick={handlePasswordReset} 
              className="cursor-pointer text-blue-500 bg-white text-blue-800 hover:underline"
            >
              Reset your password
            </button>
            </div>
            {showResetModal && <div className="-pt-[73px]"><ResetPasswordModal /></div>}
            {showEmailVerificationModal && <EmailVerificationModal />}
            <div className="flex flex-row w-full items-center justify-center">
              <button 
                type="submit" 
                className={`cursor-pointer w-[40%] font-semibold py-2 rounded ${
                  editMode && hasChanges 
                    ? 'bg-blue-900 text-white hover:bg-blue-800' 
                    : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                }`}
                disabled={!editMode || !hasChanges}
              >
                Save
              </button>
            </div>
          </form>
        </div>

        {/* Mobile Content */}
        <div className="mq780:hidden">
          <div className="flex-1 flex flex-col items-start justify-start my-10 mb-0">
            <div className="flex flex-row gap-8 mb-10">
              <img 
                src="../Avatar_Default@4x.png"
                className="h-[30vw] w-[30vw] pl-5 max-h-[150px] max-w-[150px]"
                alt="Profile"
              />
              <div className="flex flex-col gap-0 items-start">
                <p className="font-subhead text-start font-subhead text-3xl mq480:text-10xl font-semibold text-blue-900 my-0 mt-3">{`${profile.firstName} ${profile.lastName}`}</p>
                <p className="text-lg mb-0">Joined {new Date(profile.createdAt).toLocaleDateString('en-US', { month: 'short', year: 'numeric' })}</p>
                <p className="text-lg mt-1">{profile.status}</p>
                {profile.status === "UNVERIFIED" && (
                  <p 
                    onClick={handleEmailVerification}
                    className="cursor-pointer text-[#478abf] mt-1"
                  >
                    Verify Now
                  </p>
                )}
                {showEmailVerificationModal && <EmailVerificationModal />}
                {emailVerificationSent && (
                  <p className="text-green-500 mt-1">Confirmation Email has been sent!</p>
                )}
              </div>    
            </div>
          </div>
          <div className="h-[1px] bg-zinc-300 px-4 mx-4"/>
          <div className="flex flex-col gap-0 items-start px-5 py-2">
            <p className="font-subhead font-subhead text-10xl font-semibold text-blue-900 my-0 mt-3">Email</p>
            <p className="text-lg mb-5">{profile.email}</p>
            <button 
              onClick={handlePasswordReset} 
              className="cursor-pointer text-blue-500 bg-white text-blue-800 hover:underline -ml-1 -mt-1 mb-3 text-lg"
            >
              Reset your password
            </button>
          </div>
          {showResetModal && <ResetPasswordModal />}
          <div className="h-[1px] bg-zinc-300 px-4 mx-4"/>
          <div className="items-start justify-start px-4">
            <p className="font-subhead mb-5 text-start font-subhead text-10xl font-semibold text-blue-900 mb-1 mt-3 items-start justify-start whitespace-nowrap w-[50%]">Interest Categories</p>
            {editMode ? (
              <CategorySelector 
              categories={categories}
              selectedCategories={profile.interestedCategories}
              categoryError={categoryError}
              onCategoryChange={(category) => {
                setProfile(prev => {
                  if (prev) {
                    const newCategories = prev.interestedCategories.some(c => c.id === category.id)
                      ? prev.interestedCategories.filter(c => c.id !== category.id)
                      : [...prev.interestedCategories, category];
                    // Limit to 3 categories
                    return { ...prev, interestedCategories: newCategories.slice(0, 3) };
                  }
                  return prev;
                });
                setHasChanges(true);
                setCategoryError(null);
              }}
            />
            ) : (
              <InterestCategories categories={profile.interestedCategories} />
            )}
          </div> 
          <div className="h-[1px] mt-5 bg-zinc-300 px-4 mx-4"/>
          <div className="hidden flex flex-col gap-0 items-start px-5 py-2">
            <p className="font-subhead font-subhead text-10xl font-semibold text-blue-900 my-0 mt-3">Preferences</p>
                <a href="account/notification-settings" className="text-11xl text-blue-700 mt-2">Edit Preferences</a>
          </div>
          {editMode && (
            <div className="flex justify-center mt-6 mb-8">
              <button 
                onClick={handleSubmit}
                className={`w-[80%] py-2 rounded font-semibold ${
                  hasChanges 
                    ? 'bg-blue-900 text-white hover:bg-blue-800' 
                    : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                }`}
                disabled={!hasChanges}
              >
                Save
              </button>
            </div>
          )}     
        </div>
      </>
    );
  };

  return (
    <>
      {/* Desktop (>=780px) */}
      <div className="hidden mq780:flex flex-col min-h-screen">
        <Header />
        <div className="flex flex-1">
          <SideNavUserProfile activeItem="Profile"/>
          <div className="flex-1 p-8 bg-gray-100 font-subhead">
            {renderContent()}
          </div>
        </div>
        <UserFooter
          dYWLogoW1="/dyw-logow-1@2x.png"
          propAlignSelf="stretch"
          propPosition="unset"
          propTop="unset"
          propLeft="unset"
          propWidth="unset"
        />
      </div>

      {/* Mobile (<780px) */}
      <div className="mq780:hidden w-full relative bg-white overflow-hidden flex flex-col min-h-screen justify-between px-0 pb-0 box-border gap-[4px] tracking-[normal] text-center text-mini text-dark-navy font-subhead">
        <div className="flex justify-between items-center px-4 mt-3 mb-1">
          <button onClick={handleBackClick} className="text-gray-500 pt-2 bg-white">
            <ArrowLeft className='w-7 h-7 stroke-gray-300' />
          </button>
          <h2 className="text-xl font-semibold ml-6">Profile</h2>
          {editMode ? <div className="w-[65px] h-[1px]"/> : <EditButton onButtonClick={handleEditClick}/>}
        </div>
        <div className="h-[1px] bg-zinc-300 px-4 mx-4"/>
          {renderContent()}
        <div className="flex-1 flex flex-col items-start justify-start my-10 mb-0 min-h-[50vh]">
        </div>
        <footer>
          <UserFooter
            dYWLogoW1="/dyw-logow-1@2x.png"
            propAlignSelf="stretch"
            propPosition="unset"
            propTop="unset"
            propLeft="unset"
            propWidth="unset"
          />
        </footer>
      </div>
    </>
  );
};

export default UserSettings;