import { FunctionComponent, useCallback } from "react";
import MerchantHubHeader from "../../components/Merchant/MerchantHubHeader";
import Footer from "../../components/Merchant/MerchantFooter";
import Deals from "../../components/Merchant/Deals";
import MerchantHubSideNav from "../../components/Merchant/MerchantHubSideNav";
import { useNavigate } from "react-router-dom";


const MerchantHubMain: FunctionComponent = () => {
  const navigate = useNavigate();

  const onDealsClick = () => {
    navigate("/merchant-hub-deals");
  };

  const onPhotosVideosClick = () => {
    navigate("/merchant-hub-deals");
  };

  const onBusinessInfoClick = () => {
    navigate("/merchant-hub-deals");
  };

  return (
    <div className="flex flex-col">
    <MerchantHubHeader selected="Deals"/>
    <main className="flex-1 flex min-h-screen font-subhead flex-row">
      <MerchantHubSideNav selected="My Deals"/>  
      <Deals />
    </main>
      <footer className="mt-auto"> {/* This will push the footer to the bottom */}
          <Footer
            dYWLogoW1="/dyw-logow-1@2x.png"
            propAlignSelf="stretch"
            propPosition="unset"
            propTop="unset"
            propLeft="unset"
            propWidth="unset"
          />
        </footer>  
    </div>
  );
};

export default MerchantHubMain;