import React, { useState, useEffect } from "react";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate } from 'react-router-dom';
import { Eye, EyeOff } from "lucide-react";
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';

interface SignUpFormProps {
  onGoogleSignUp: () => void;
  onFacebookSignUp: () => void;
  onSignUp: () => void;
  formData: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    gender: string;
    birthdate: string;
    recaptchaResponse: string;
  };
  setFormData: React.Dispatch<React.SetStateAction<SignUpFormProps['formData']>>;
}

interface FormErrors {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  gender: string;
  birthdate: string;
}

function SignUpForm({ onGoogleSignUp, onFacebookSignUp, onSignUp, formData, setFormData }: SignUpFormProps) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [birthday, setBirthday] = useState<Date | null>(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<FormErrors>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    gender: '',
    birthdate: '',
  });

  function isAndroidDevice() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isAndroid = /android/.test(userAgent);
    const isMobile = /mobile/.test(userAgent);
    
    // Additional check for Android-specific feature
    const hasAndroidDatePicker = typeof window.Intl !== 'undefined' && 
                                 typeof window.Intl.DateTimeFormat !== 'undefined' && 
                                 typeof window.Intl.DateTimeFormat().resolvedOptions().calendar === 'string' &&
                                 window.Intl.DateTimeFormat().resolvedOptions().calendar === 'gregory';
  
    return isAndroid && hasAndroidDatePicker && isMobile;
  }

  useEffect(() => {
    if (!executeRecaptcha) {
      // console.log('Execute recaptcha not yet available');
      return;
    }
    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  useEffect(() => {
    if (birthday) {
      setFormData(prevData => ({
        ...prevData,
        birthdate: format(birthday, 'yyyy-MM-dd')
      }));
    }
  }, [birthday]);

  const handleReCaptchaVerify = async () => {
    if (!window.grecaptcha) {
      // console.log('reCAPTCHA not yet available');
      return;
    }
    
    try {
      const token = await window.grecaptcha.execute('6LdJ3g8qAAAAAMQZLiXE3e_hQnJneWLsmNQkicFU', {action: 'signup'});
      setFormData(prevData => ({
        ...prevData,
        recaptchaResponse: token
      }));
    } catch (error) {
      console.error('reCAPTCHA execution failed:', error);
    }
  };

  const validateForm = (): boolean => {
    let isValid = true;
    const newErrors: FormErrors = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      gender: '',
      birthdate: '',
    };

    // First Name validation
    if (!formData.firstName.trim()) {
      newErrors.firstName = "First name is required";
      isValid = false;
    } else if (formData.firstName.length > 50) {
      newErrors.firstName = "First name must not exceed 50 characters";
      isValid = false;
    }

    // Last Name validation
    if (!formData.lastName.trim()) {
      newErrors.lastName = "Last name is required";
      isValid = false;
    } else if (formData.lastName.length > 50) {
      newErrors.lastName = "Last name must not exceed 50 characters";
      isValid = false;
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Invalid email format";
      isValid = false;
    }

    // Update the password regex in the validateForm function
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?])(?=.*[^a-zA-Z0-9]).{8,}$/;
    if (!formData.password) {
      newErrors.password = "Password is required";
      isValid = false;
    } else if (formData.password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long";
      isValid = false;
    } else if (!passwordRegex.test(formData.password)) {
      newErrors.password = "Password must include at least one lowercase letter, one uppercase letter, one number, and one special character";
      isValid = false;
    }

    // Confirm Password validation
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
      isValid = false;
    }

    // Gender validation
    if (!formData.gender) {
      newErrors.gender = "Gender is required";
      isValid = false;
    }

    // Birthdate validation
    if (!formData.birthdate) {
      newErrors.birthdate = "Birthdate is required";
      isValid = false;
    } else {
      const birthDate = new Date(formData.birthdate);
      const today = new Date();
      var age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      if (age < 13) {
        newErrors.birthdate = "You must be at least 13 years old";
        isValid = false;
      } else if (age > 120) {
        newErrors.birthdate = "Invalid birthdate";
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await handleReCaptchaVerify();
    
    if (validateForm()) {
      onSignUp();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (name === 'birthdate') {
      // For native date input (Android)
      setBirthday(new Date(value));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleLogInClick = () => {
    navigate("/login")
  };

  const handleToSClick = () => {
    navigate("/terms-of-service")
  };

  const handlePPClick = () => {
    navigate("/privacy-policy")
  };

  return (
    <div className="min-h-screen bg-gray-200 flex justify-center items-center transform pb-20">
      <div className="bg-white shadow-lg rounded-lg flex overflow-hidden pb-10">
        {/* Profile Image */}
        <div className="hidden lg:block lg:w-1/2 pl-10" style={{ maxWidth: '512px' }}>
          <img
            alt="Family"
            src="family-walking-to-plane.jpg"
            className="w-full h-full object-cover" // Removed specific width and height classes
            style={{ borderRadius: '50%', width: '400px', height: '400px' }} // Set width and height directly
          />
        </div>
        
        {/* Form Section */}
        <div className="lg:w-96 w-full pt-15 px-6 mq400:px-[10vw]">
          <h2 className="text-4xl font-black text-center mb-2">Sign up for DoYouWeekend</h2>
          <div className="text-center font-bold text-sm mb-4">
            Already have an account? <a href="#" className="text-blue-900 hover:underline" onClick={handleLogInClick}>Log in</a>
          </div>
          <div className="text-center text-xs mb-0 mt-10">
            By signing up, you agree to DoYouWeekend's{' '}
            <a onClick={handleToSClick} className="cursor-pointer text-blue-300">Terms of Service</a> and{' '}
            <a onClick={handlePPClick} className="cursor-pointer text-blue-300">Privacy Policy</a>
          </div>

          {/* Social Buttons */}
          <div className="hidden flex flex-col mb-4 w-full">
          <button className="font-black cursor-pointer flex gap-5 items-start px-16 py-3 mt-2.5 tracking-tight text-center bg-white rounded-lg border border-solid border-neutral-500 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/10aa6d17817e1308b17de58515f5ae46b64fbaa5457677d8429d28810b3ccfa4?apiKey=0d607e805d95499aa95fa0a93ed5f083&" alt="" className="shrink-0 self-start aspect-square w-[29px]" />
            <span className="flex-auto my-auto">Sign up with Google</span>
          </button>
          <button className="font-black font-black cursor-pointer flex gap-5 items-start px-16 py-3 mt-2.5 tracking-tight text-center bg-white rounded-lg border border-solid border-neutral-500 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/e93cd57f7182dc24a8cec5cdf54ea0f21ffb483e00ff3fa9908ec6f8f9f15a83?apiKey=0d607e805d95499aa95fa0a93ed5f083&" alt="" className="shrink-0 self-start aspect-square w-[29px]" />
            <span className="flex-auto my-auto">Sign up with Facebook</span>
          </button>
          </div>

          <div className="text-center text-sm mb-8"></div>
          {/* Write "or" in div when OAuth is implemented */}

          {/* Form Fields */}
          <form onSubmit={handleSubmit} className="mb-6 flex flex-wrap justify-between gap-3">
          <div className="w-full pr-4 flex flex-row justify-between">
            <div className="w-[45%] pr-2">
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                placeholder="First Name"
                className={`w-full p-2 border ${errors.firstName ? 'border-red-500' : 'border-light-gray'} rounded-md`}
                autoComplete="given-name"
              />
              {errors.firstName && <p className="text-red-500 text-xs mt-1">{errors.firstName}</p>}
            </div>
            <div className="w-[45%]">
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                placeholder="Last Name"
                className={`w-full p-2 border ${errors.lastName ? 'border-red-500' : 'border-light-gray'} rounded-md`}
                autoComplete="family-name"
              />
              {errors.lastName && <p className="text-red-500 text-xs mt-1">{errors.lastName}</p>}
            </div>
          </div>
          <div className="w-full">
            <select
              name="gender"
              value={formData.gender}
              onChange={handleInputChange}
              className={`cursor-pointer w-full h-[34px] mq1050:h-auto text-zinc-500 bg-white py-2 pr-6 pl-1 border ${errors.gender ? 'border-red-500' : 'border-light-gray'} rounded-md mt-3`}
              autoComplete="sex"
            >
              <option value="">Select Gender</option>
              <option value="MALE">Male</option>
              <option value="FEMALE">Female</option>
              <option value="PREFER_NOT_TO_SAY">Prefer not to say</option>
            </select>
            {errors.gender && <p className="text-red-500 text-xs mt-1">{errors.gender}</p>}
          </div>
          <div className="w-full pr-4">
            <p className="hidden p-0 mb-1">Enter your Birthday:</p> 
              <DatePicker
                selected={birthday}
                onChange={(date: Date | null) => setBirthday(date)}
                dateFormat="dd.MM.yyyy"
                className="w-full min-w-[287px] text-start p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mt-2"
                placeholderText="Enter your birthday"
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={100}
                maxDate={new Date()}
                id="birthdate"
              />
            {errors.birthdate && <p className="text-red-500 text-xs mt-1">{errors.birthdate}</p>}
          </div>
          <div className="w-full pr-4">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email"
              className={`w-full p-2 border ${errors.email ? 'border-red-500' : 'border-light-gray'} rounded-md mt-3`}
              autoComplete="email"
            />
            {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
          </div>
          <div className="w-full pr-4 relative">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              placeholder="Password"
              className={`w-full p-2 border ${errors.password ? 'border-red-500' : 'border-light-gray'} rounded-md mt-3`}
              autoComplete="new-password"
              id="new-password"
              required
            />
            <span
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <EyeOff className="stroke-black w-4 h-4 mt-3 ml-2 -mr-1" /> : <Eye className="stroke-black w-4 h-4 mt-3 ml-2 -mr-1" />}
            </span>
          </div>
          {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password}</p>}
          <div className="w-full pr-4 relative">
            <input
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              placeholder="Confirm Password"
              className={`w-full p-2 border ${errors.confirmPassword ? 'border-red-500' : 'border-light-gray'} rounded-md mt-3`}
              autoComplete="new-password"
              id="confirm-password"
              required
            />
            <span
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 cursor-pointer"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? <EyeOff className="stroke-black w-4 h-4 mt-3 ml-2 -mr-1" /> : <Eye className="stroke-black w-4 h-4 mt-3 ml-2 -mr-1" />}
            </span>
          </div>
            {errors.confirmPassword && <p className="text-red-500 text-xs mt-1">{errors.confirmPassword}</p>}

          {/* Sign Up Button */}
          <button
            type="submit"
            className="font-bold w-full bg-blue-500 text-white p-3 px-6 rounded-md hover:bg-blue-700 mt-4"
          >
            Sign up
          </button>
        </form>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;