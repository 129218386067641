import React, { FunctionComponent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserFooter from "../../components/User/UserFooter";
import Header from "../../components/User/Header";
import SignUpForm from "../../components/User/SignUpForm";
import InterestsPopup from "../../components/User/InterestsPopup"; 
import ConfirmationPopup from "../../components/User/SignupConfirmationPopup";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from "axios";
import { AxiosError } from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

interface Window {
  grecaptcha: {
    ready: (callback: () => void) => void;
    execute: (siteKey: string, options: { action: string }) => Promise<string>;
  };
}
 
interface ErrorBoundaryProps {
    children: React.ReactNode;
  }
  
  interface ErrorBoundaryState {
    hasError: boolean;
  }
  
  class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
      return { hasError: true };
    }
  
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
      // console.log('Caught an error:', error, errorInfo);
    }
  
    render(): React.ReactNode {
      if (this.state.hasError) {
        return <h1>Something went wrong. Please try again.</h1>;
      }
  
      return this.props.children;
    }
  }

const H42SignUpForm: FunctionComponent = () => {
    const navigate = useNavigate();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [isReCaptchaReady, setIsReCaptchaReady] = useState(false);
    const [showInterestsPopup, setShowInterestsPopup] = useState(false);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedInterests, setSelectedInterests] = useState<string[]>([]);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        gender: '',
        birthdate: '',
        recaptchaResponse: ''
    });

    const handleGoogleSignUp = () => {
        //Add OAuth Functionality
    };

    const handleFacebookSignUp = () => {
        //Add OAuth Functionality
    };

    const handleSignUp = () => {
        setShowInterestsPopup(true);
    };

    useEffect(() => {
        if (window.grecaptcha) {
          window.grecaptcha.ready(() => {
            setIsReCaptchaReady(true);
          });
        }
      }, []);

      const sendSignupData = async (interests: string[]) => {
        try {
            // console.log('Executing reCAPTCHA...');
            const recaptchaToken = await window.grecaptcha.execute('6LdJ3g8qAAAAAMQZLiXE3e_hQnJneWLsmNQkicFU', {action: 'signup'});
            // console.log('reCAPTCHA token obtained:', recaptchaToken);
    
            // console.log('Sending signup data...');
            const response = await axios.post(`${API_BASE_URL}/v1/users/register/customer`, {
                ...formData,
                interestedCategoryIds: interests,
                birthdate: formData.birthdate,
                gender: formData.gender.toUpperCase(),
                recaptchaResponse: recaptchaToken,
            });
            // console.log('Signup response:', response);
    
            if (response.status === 200 || response.status === 201) {
                // console.log('Signup successful, attempting login...');
                const loginResponse = await axios.post(`${API_BASE_URL}/v1/users/auth/login`, {
                    email: formData.email,
                    password: formData.password,
                    recaptchaResponse: recaptchaToken,
                });
                // console.log('Login response:', loginResponse.data);
                
                if (loginResponse.status === 200 || loginResponse.status === 201) {
                    const { token, userType } = loginResponse.data;
                    // console.log('Received token:', token);
                    localStorage.setItem('jwtToken', token);
                    localStorage.setItem('userType', userType);
            
                    // Add this: Configure axios to use the token for future requests
                    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            
                    // Now try to fetch the user profile
                    try {
                        const profileResponse = await axios.get(`${API_BASE_URL}/v1/users/customer/profile`, {
                            headers: {
                              Authorization: `Bearer ${token}`
                            }
                          });
                        // console.log('Profile response:', profileResponse.data);
                        navigate('/');
                    } catch (profileError) {
                        console.error('Error fetching profile:', profileError);
                        alert('Signed up successfully, but there was an issue accessing your profile. Please try logging in.');
                        navigate('/login');
                    }
                } else {
                    setShowConfirmationPopup(true);
                    setShowInterestsPopup(false);
                }
            }
        } catch (error) {
            console.error('Error during signup:', error);
            if (axios.isAxiosError(error)) {
                console.error('Axios error:', error.response?.data);
                if (error.response?.status === 400 && error.response?.data?.validationErrors) {
                    const errorMessages = Object.entries(error.response.data.validationErrors)
                        .map(([field, message]) => `${field}: ${message}`)
                        .join('\n');
                    alert(`Error(s) occurred:\n${errorMessages}`);
                } else if (error.response?.data?.message) {
                    alert(error.response.data.message);
                } else {
                    alert('An error occurred during signup. Please check the console for more details.');
                }
            } else {
                console.error('Non-Axios error:', error);
                alert('An unexpected error occurred. Please check the console for more details.');
            }
        }
    };

    const handleSaveInterests = (interests: string[]) => {
        setSelectedInterests(interests);
        if (interests.length > 0) {
            sendSignupData(interests);
        
        } else {
            alert("Please select at least one interest.");
        }
    };

    const handleConfirmationClick = () => {
        navigate('/');
    };

    return (
        <ErrorBoundary>
        <div className="w-full relative bg-white overflow-hidden flex flex-col justify-start px-0 pb-0 box-border gap-[4px] tracking-[normal] text-center text-mini text-dark-navy font-subhead pt-[73px] mq780:pt-0">
            <Header />
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY ||""}>
                <SignUpForm
                    formData={formData}
                    setFormData={setFormData}
                    onGoogleSignUp={handleGoogleSignUp}
                    onFacebookSignUp={handleFacebookSignUp}
                    onSignUp={handleSignUp}
                />
            </GoogleReCaptchaProvider>
            <UserFooter
                dYWLogoW1="/dyw-logow-1@2x.png"
                propAlignSelf="stretch"
                propPosition="unset"
                propTop="unset"
                propLeft="unset"
                propWidth="unset"
            />
            <InterestsPopup
                isOpen={showInterestsPopup}
                onClose={() => setShowInterestsPopup(false)}
                onSave={handleSaveInterests}
            />
            <ConfirmationPopup
                isOpen={showConfirmationPopup}
                onClose={() => setShowConfirmationPopup(false)}
                onSave={handleConfirmationClick}
            />
        </div>
        </ErrorBoundary>
    );
};

export default H42SignUpForm;