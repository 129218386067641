import React, { FC, useState, useCallback } from "react";
import { DateTime } from "luxon";

type TimePickerProps = {
  label: string;
  selectedTime: DateTime | null;
  onSelectTime: (time: DateTime) => void;
  isOpen: boolean;
  onToggle: () => void;
};

const convertTo12HourFormat = (time: DateTime | null) => {
  return time ? time.toFormat('h:mm a') : '';
};

const TimePicker: FC<TimePickerProps> = ({ label, selectedTime, onSelectTime, isOpen, onToggle }) => {
  const times = Array.from({ length: 24 * 2 }, (_, i) => {
    const hours = Math.floor(i / 2);
    const minutes = i % 2 === 0 ? 0 : 30;
    return DateTime.fromObject({ hour: hours, minute: minutes });
  });

  const handleTimeSelection = (time: DateTime) => {
    onSelectTime(time);
    onToggle();
  };

  return (
    <div className="relative shadow-sm">
      <button
        type="button"
        className="bg-white cursor-pointer whitespace-nowrap flex items-center justify-between p-2.5 rounded-md border border-solid border-zinc-800 w-full w-[128px]"
        onClick={onToggle}
      >
        <div className="flex-grow pr-4 min-w-[75px]">
          <div className="text-left">{convertTo12HourFormat(selectedTime)}</div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/800b4a9589a597bd5be493483745c09ae03463bb4e03e1da7fba54614634af55?apiKey=0d607e805d95499aa95fa0a93ed5f083&"
          alt={label}
          className="shrink-0 w-5 aspect-square"
        />
      </button>
      {isOpen && (
        <div className="absolute z-10 bg-white border border-zinc-800 rounded-md mt-2 max-h-60 overflow-y-auto w-full shadow-sm">
          {times.map((time) => (
            <div
              key={time.toISO()}
              className="p-2 hover:bg-gray-200 cursor-pointer"
              onClick={() => handleTimeSelection(time)}
            >
              {convertTo12HourFormat(time)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

type DayProps = {
  day: string;
  onOpenTimeSelect: (time: DateTime) => void;
  onCloseTimeSelect: (time: DateTime) => void;
  onClosedClick: () => void;
  isClosed: boolean;
  openTime: DateTime | null;
  closeTime: DateTime | null;
  openDropdown: string | null;
  onToggleDropdown: (dropdown: string) => void;
};

const Day: FC<DayProps> = ({
  day,
  onOpenTimeSelect,
  onCloseTimeSelect,
  onClosedClick,
  isClosed,
  openTime,
  closeTime,
  openDropdown,
  onToggleDropdown
}) => (
  <div className="flex flex-row w-full gap-5 pt-6 items-start justify-between 580px:flex-col 580px:items-start">
    <div className="w-1/6 font-semibold">{day}</div>
    <div className="flex w-3/6 gap-2">
      <TimePicker 
        label="Opens at" 
        selectedTime={openTime}
        onSelectTime={onOpenTimeSelect}
        isOpen={openDropdown === `${day}-open`}
        onToggle={() => onToggleDropdown(`${day}-open`)}
      />
      <TimePicker 
        label="Closes at" 
        selectedTime={closeTime}
        onSelectTime={onCloseTimeSelect}
        isOpen={openDropdown === `${day}-close`}
        onToggle={() => onToggleDropdown(`${day}-close`)}
      />
    </div>
    <div className="flex items-center gap-2">
      <input
        type="checkbox"
        checked={isClosed}
        onChange={onClosedClick}
        className="shrink-0 rounded-md border border-solid border-zinc-700 h-[19px] w-[19px]"
      />
      <div className="my-auto">Closed</div>
    </div>
  </div>
);

interface OpeningHoursProps {
  initialHours?: {
    [key: string]: {
      openTime?: DateTime | null;
      closeTime?: DateTime | null;
      closed: boolean;
    };
  };
  onChange: (hours: {
    [key: string]: {
      openTime: DateTime | null;
      closeTime: DateTime | null;
      isClosed: boolean;
    };
  }) => void;
  disabled?: boolean;
}

type Schedule = Record<string, { openTime: DateTime | null; closeTime: DateTime | null; isClosed: boolean }>;

const ORDERED_DAYS = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

const OpeningHours: FC<OpeningHoursProps> = ({ onChange, initialHours, disabled }) => {
  const [schedule, setSchedule] = useState<Schedule>(() => {
    if (initialHours) {
      return ORDERED_DAYS.reduce((acc, day) => {
        const hours = initialHours[day] || {};
        acc[day] = {
          openTime: hours.openTime ?? null,
          closeTime: hours.closeTime ?? null,
          isClosed: hours.closed || false
        };
        return acc;
      }, {} as Schedule);
    } else {
      const defaultTime = (hour: number) => DateTime.local().set({ hour, minute: 0, second: 0, millisecond: 0 });
      return ORDERED_DAYS.reduce((acc, day) => {
        acc[day] = { openTime: defaultTime(8), closeTime: defaultTime(20), isClosed: false };
        return acc;
      }, {} as Schedule);
    }
  });

  const [openDropdown, setOpenDropdown] = useState<string | null>(null);

  const handleTimeSelect = useCallback((day: string, isOpenTime: boolean, time: DateTime) => {
    if (disabled) return;
    setSchedule(prev => {
      const newSchedule = {
        ...prev,
        [day]: { 
          ...prev[day], 
          [isOpenTime ? 'openTime' : 'closeTime']: time
        }
      };
      onChange(newSchedule);
      return newSchedule;
    });
  }, [onChange, disabled]);

  const handleClosedClick = useCallback((day: string) => {
    if (disabled) return;
    setSchedule(prev => {
      const newSchedule = {
        ...prev,
        [day]: { ...prev[day], isClosed: !prev[day].isClosed }
      };
      onChange(newSchedule);
      return newSchedule;
    });
  }, [onChange, disabled]);

  const handleToggleDropdown = useCallback((dropdown: string) => {
    setOpenDropdown(prev => prev === dropdown ? null : dropdown);
  }, []);

  return (
    <div className="flex flex-col text-base leading-7 max-w-[634px] text-zinc-800">
      {ORDERED_DAYS.map(day => {
        const { openTime, closeTime, isClosed } = schedule[day];
        return (
          <Day
            key={day}
            day={day}
            onOpenTimeSelect={(time) => handleTimeSelect(day, true, time)}
            onCloseTimeSelect={(time) => handleTimeSelect(day, false, time)}
            onClosedClick={() => handleClosedClick(day)}
            isClosed={isClosed}
            openTime={openTime}
            closeTime={closeTime}
            openDropdown={openDropdown}
            onToggleDropdown={handleToggleDropdown}
          />
        );
      })}
    </div>
  );
};

export default OpeningHours;