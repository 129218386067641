import { FunctionComponent, useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { X, ChevronDown } from "lucide-react";
import axios from 'axios';
import { createOrder, createCheckoutSession } from '../../api/stripe/stripeOrders';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

export type LikedItemType = {
  id: string;
  title: string;
  description: string;
  image: string;
  price?: number;
  dealpage?: string;
};

interface Itinerary {
  id: string;
  name: string;
  offerIds: string[];
}

const LikedItem: FunctionComponent<LikedItemType> = ({
  id,
  title,
  description,
  image,
  price,
  dealpage="/",
}) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isHoursDropdownOpen, setIsHoursDropdownOpen] = useState(false);
    const [isContactDropdownOpen, setContactDropdownOpen] = useState(false);
    const [isConfirmationPopupOpen, setIsConfirmationPopupOpen] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [itineraries, setItineraries] = useState<Itinerary[]>([]);
    const [selectedItineraries, setSelectedItineraries] = useState<string[]>([]);
    const [initialSelectedItineraries, setInitialSelectedItineraries] = useState<string[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchItineraries = async () => {
            try {
                const jwtToken = localStorage.getItem("jwtToken");
                if (!jwtToken) {
                    throw new Error('No JWT token found');
                }

                const response = await fetch(`${API_BASE_URL}/v1/itineraries`, {
                    headers: {
                        'Authorization': `Bearer ${jwtToken}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch itineraries');
                }
                const data: Itinerary[] = await response.json();
                setItineraries(data);

                // Find itineraries that already contain the offer
                const preSelectedItineraries = data
                    .filter(itinerary => itinerary.offerIds.includes(id))
                    .map(itinerary => itinerary.id);
                setSelectedItineraries(preSelectedItineraries);
                setInitialSelectedItineraries(preSelectedItineraries);
            } catch (error) {
                console.error('Error fetching itineraries:', error);
            }
        };
        fetchItineraries();
    }, [id]);

    const toggleHoursDropdown = () => {
        setIsHoursDropdownOpen(!isHoursDropdownOpen);
    };

    const toggleContactDropdown = () => {
        setContactDropdownOpen(!isContactDropdownOpen);
    };

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
        setIsConfirmationPopupOpen(false);
    };

    const toggleConfirmationPopup = () => {
      setIsPopupOpen(false);
      setIsConfirmationPopupOpen(false);
    };

    const handleBookNow = async () => {
        try {
            const orderResponse = await createOrder(id, quantity);
            if (orderResponse && orderResponse.id) {
                const checkoutSessionResponse = await createCheckoutSession(orderResponse.id);
                
                if (checkoutSessionResponse) {
                    navigate(`/checkout/${orderResponse.id}`);
                }
            }
        } catch (error) {
            console.error('Error during booking process:', error);
        }
    };

    const handleItinerarySelection = (e: React.ChangeEvent<HTMLInputElement>, itineraryId: string) => {
        if (e.target.checked) {
            setSelectedItineraries(prev => [...prev, itineraryId]);
        } else {
            setSelectedItineraries(prev => prev.filter(id => id !== itineraryId));
        }
    };

    const handleAddToItineraries = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        
        try {
            const jwtToken = localStorage.getItem("jwtToken");
            if (!jwtToken) {
                throw new Error('No JWT token found');
            }
        
            const itinerariesToAdd = selectedItineraries.filter(itId => !initialSelectedItineraries.includes(itId));
            const itinerariesToRemove = initialSelectedItineraries.filter(itId => !selectedItineraries.includes(itId));
        
            for (const itineraryId of itinerariesToAdd) {
                await fetch(`${API_BASE_URL}/v1/itineraries/${itineraryId}/offers/${id}`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${jwtToken}`,
                        'Content-Type': 'application/json'
                    }
                });
            }
        
            for (const itineraryId of itinerariesToRemove) {
                await fetch(`${API_BASE_URL}/v1/itineraries/${itineraryId}/offers/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${jwtToken}`,
                        'Content-Type': 'application/json'
                    }
                });
            }
        
            setInitialSelectedItineraries(selectedItineraries);
            togglePopup();
            // console.log('Itineraries updated successfully');
        } catch (error) {
            console.error('Error updating itineraries:', error);
        }
    };

    const onNewItineraryClick = () => {
        setIsPopupOpen(false);
        setIsConfirmationPopupOpen(!isConfirmationPopupOpen);
    };

    const onViewItineraryClick = () => {
        setIsConfirmationPopupOpen(false);
        navigate("/itineraries");
    };

    const onItemClick = () => {
      navigate(`/offer/${id}`);
    };

    const truncateString = (str: string, num: number): string => {
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + '...';
    };

    const truncatedDescription = useMemo(() => truncateString(description, 240), [description]);

    return (
        <section className="w-full h-[40vh] bg-zinc-50">
            <div className="flex flex-row gap-4 justify-start">
                <img 
                    src={image}
                    className="size-56 items-center justify-center p-5 ml-8 rounded"
                />
                <div className="flex flex-col max-w-[700px]">
                    <h1 onClick={onItemClick} className="cursor-pointer font-subhead text-semibold text-black m-0 mb-1 mt-5">{title}</h1>
                    <p className="font-subhead text-base text-zinc-500 mt-1 leading-[28px]">{truncatedDescription}</p>
                    <div className="flex flex-row gap-3 items-start">
                        <button 
                            className="w-[210px] cursor-pointer [border:none] py-[11px] px-2 bg-[transparent] flex flex-row items-center justify-center relative whitespace-nowrap z-[1]"
                            onClick={togglePopup}
                        >
                            <div className="h-full w-full absolute !m-[0] top-[0px] right-[1px] bottom-[0px] left-[0px] rounded-md bg-white box-border border-[1px] border-solid border-main-teal" />
                            <b className="w-[167px] relative text-mini tracking-[-0.01em] leading-[19px] inline-block font-subhead text-main-teal hover:text-blue-800 text-center z-[1]">
                                Add to itinerary
                            </b>
                        </button>
                        <button className="w-[210px] rounded-lg cursor-pointer [border:none] py-[11px] px-2 bg-main-teal flex flex-row items-center justify-center relative whitespace-nowrap z-[1]">
                            <div className="h-full w-full absolute !m-[0] top-[0px] right-[1px] bottom-[0px] left-[0px] rounded-md bg-main-teal box-border border-[1px] border-solid border-main-teal hover:border-blue-400" />
                            <b onClick={handleBookNow} className="w-[167px] relative text-mini tracking-[-0.01em] leading-[19px] inline-block font-subhead text-zinc-100 text-center z-[1]">
                                Book Now
                            </b>
                        </button>
                    </div>
                </div>
                <div className="hidden mq1050:flex w-[248px] my-5 text-subhead font-subhead flex flex-col items-center justify-start pt-0.5 px-0 pb-0 box-border min-w-[248px] text-xs mq800:flex-1">
                    <div className="hidden self-stretch flex flex-col items-start justify-start gap-[19px]">
                        <div onClick={toggleHoursDropdown} className="hover:text-blue-800 cursor-pointer relative tracking-[-0.01em] leading-[18px] font-medium z-[1]">
                            Hours & Directions
                        </div>
                        {isHoursDropdownOpen && (
                          <div
                            className="py-2 w-inherit pb-0 whitespace-nowrap cursor-pointer text-sm hover:bg-gray-100"
                          >
                            Today: 9:00AM - 10:00PM
                          </div>
                        )}
                        <div className="self-stretch h-px relative box-border z-[1] border-t-[1px] border-solid border-main-teal" />
                        <div onClick={toggleContactDropdown} className="hover:text-blue-800 cursor-pointer relative tracking-[-0.01em] font-medium inline-block min-w-[86px] z-[1]">
                            Contact Info
                        </div>
                        {isContactDropdownOpen && (
                          <div
                            className="py-2 w-inherit pb-0 whitespace-nowrap cursor-pointer text-sm hover:bg-gray-100"
                          >
                            Email: someEmail@gmail.com<br />Tel: +1 173 405 124
                        </div>
                        )}
                        <div className="self-stretch h-px relative box-border z-[1] border-t-[1px] border-solid border-main-teal" />
                        <b className="hover:text-blue-800 cursor-pointer relative [text-decoration:underline] text-main-teal z-[1]">
                            Remove from Likes
                        </b>
                    </div>
                </div>
                <div />
            </div>
            <div className="self-stretch h-1 box-border mt-7 shrink-0 border-t-[1px] border-solid border-silver-100" />
            {isPopupOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="relative bg-white p-8 rounded-lg max-w-md w-full font-subhead items-center justify-center">
                        <X className="cursor-pointer absolute top-3 right-3 w-7 h-7 stroke-zinc-700 font-light" onClick={togglePopup} />
                        <h2 className="text-19xl font-light mb-10 text-center justify-center">Select an Itinerary</h2>
                        <ul className="cursor-pointer">
                            {itineraries.map((itinerary, index) => (
                                <li key={itinerary.id} className="mb-2 list-none">
                                    <input 
                                        type="checkbox" 
                                        id={`itinerary${index}`} 
                                        name={`itinerary${index}`}
                                        checked={selectedItineraries.includes(itinerary.id)}
                                        onChange={(e) => handleItinerarySelection(e, itinerary.id)}
                                    />
                                    <label htmlFor={`itinerary${index}`} className="ml-2 font-semibold cursor-pointer">{itinerary.name}</label>
                                </li>
                            ))}
                        </ul>
                        <div className="flex items-center justify-center flex-col">
                            <div className="bg-zinc-400 w-80 h-[1px] my-8" />
                            <button 
                                onClick={handleAddToItineraries} 
                                className={`cursor-pointer mt-0 px-auto w-64 font-subhead font-semibold my-3 py-2 rounded ${
                                    selectedItineraries.length !== initialSelectedItineraries.length 
                                        ? 'bg-main-teal text-white hover:bg-blue-800' 
                                        : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                }`}
                                disabled={selectedItineraries.length === initialSelectedItineraries.length}
                            >
                                Add to Itinerary
                            </button>
                            <button onClick={onNewItineraryClick} className="cursor-pointer text-main-teal mt-0 px-auto w-64 font-subhead font-semibold bg-white border border-solid border-main-teal py-2 rounded">
                                Add to new Itinerary
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {isConfirmationPopupOpen && (
              <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div className="relative bg-white pt-8 pb-8 rounded-lg w-full max-w-[min(500px,65vw)] max-h-[80vh] overflow-y-auto font-subhead px-5">
                  <X className="cursor-pointer absolute top-3 right-3 w-7 h-7 stroke-zinc-700 font-light" onClick={toggleConfirmationPopup} />
                  <h2 className="text-11xl text-black font-semibold mb-6 text-center">Success</h2>
                  <p className="text-xl text-zinc-800 font-base mb-6 text-center">
                      <p className="text-19xl text-blue-800 font-semibold text-center m-0">{title}</p>
                      was added to your Itinerary!
                  </p>
                  <div className="flex flex-col items-center">
                      <button onClick={onViewItineraryClick} className="w-full my-3 cursor-pointer hover:bg-blue-800 font-semibold mb-6 bg-main-teal text-white text-xl py-4 px-6 max-w-[80%] cursor-pointer rounded-lg">
                          View Your Itinerary
                      </button>
                    <div className="bg-zinc-400 w-full h-[1px] mb-6" />
                    <p className="text-[15px] text-blue-800 font-base mb-1 mt-0 text-center">Other popular Destinations Near</p>
                    <p className="text-base text-blue-800 font-bold mb-2 mt-0 text-center">{title}</p>
                  </div>
                  {/* @Recommendation Service */}
                  <div className="flex flex-row gap-0 mt-2 gap-4 items-center justify-center w-full bg-white-100">
                    <div onClick={() => navigate("/offer/"+{id})} className="flex flex-col gap-1 items-center justify-center text-center">
                      <img src="apple-picking@2x.png" className="w-[min(20vw,140px)] rounded-sm" />
                      <p className="text-xs text-zinc-700 font-subhead  mt-1">{title}</p>
                    </div>
                    <div onClick={() => navigate("/offer/"+{id})} className="flex flex-col gap-1 items-center justify-center text-center">
                      <img src="apple-picking@2x.png" className="w-[min(20vw,140px)] rounded-sm" />
                      <p className="text-xs text-zinc-700 font-subhead  mt-1">{title}</p>
                    </div>
                    <div onClick={() => navigate("/offer/"+{id})} className="flex flex-col gap-1 items-center justify-center text-center">
                      <img src="apple-picking@2x.png" className="w-[min(20vw,140px)] rounded-sm" />
                      <p className="text-xs text-zinc-700 font-subhead  mt-1">{title}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </section>
    );
};

export default LikedItem;
