// src/hooks/useAxiosWithTokenRefresh.ts

import { useEffect, useRef } from 'react';
import axios, { AxiosInstance } from 'axios';
import { checkAndRefreshToken } from '../api/RefreshToken';

const useAxiosWithTokenRefresh = (): AxiosInstance => {
  const axiosInstance = useRef(axios.create());

  useEffect(() => {
    const requestInterceptor = axiosInstance.current.interceptors.request.use(
      async (config) => {
        await checkAndRefreshToken();
        const token = localStorage.getItem('jwtToken');
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    return () => {
      axiosInstance.current.interceptors.request.eject(requestInterceptor);
    };
  }, []);

  return axiosInstance.current;
};

export default useAxiosWithTokenRefresh;