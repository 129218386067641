import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const OrderSuccess: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(10);
  

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isOpen) {
      timer = setInterval(() => {
        setCountdown((prevCount) => {
          if (prevCount <= 1) {
            clearInterval(timer);
            navigate("/");
            return 0;
          }
          return prevCount - 1;
        });
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [isOpen, navigate]);

  const handleMyBookingsClick = () => {
    navigate("/account/bookings");
  };

  if (!isOpen) return null;

  return (
    <div className="fixed font-subhead flex flex-col inset-0 bg-gray bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg min-w-[250px] w-full h-full text-center">
        <img className='w-[min(40vw,200px)] h-[min(40vw,200px)] mt-16' src="/checkmark-dyw.png" alt="Checkmark" />
        <h2 className="text-2xl font-semibold mb-6 text-cyan-600 mt-16">You paid for <br /> your Order</h2>
        <p className="text-base font-semilight mb-4 text-zinc-400 max-w-[min(300px,70vw)] mx-auto">
          Check your email for your QR code you use to Pay!
        </p>
        <p className="text-sm text-gray-500 mt-2">
          Redirecting in {countdown} seconds...
        </p>
        <div className='w-full mt-10 items-center justify-center flex py-8'>
          <button
            onClick={handleMyBookingsClick}
            className="w-full max-w-[450px] mx-12 py-4 font-subhead rounded-lg font-semibold cursor-pointer text-xl bg-main-teal text-white" 
          >
            View my Bookings
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderSuccess;