import { FunctionComponent, useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

export type RounderType = {
  coverImage: string;
  activityTitle: string;
  activityLink?: string;
  categories?: string;
  distance: string;
  numReviews?: string;
  priceOld?: string;
  priceNew: string;
  onActivityClick?: () => void;
};

export type Offer = {
  id: string;
  images: string[];
  name: string;
  longitude: number;
  latitude: number;
  categories: string[];
  price: number;
  description?: string;
  promotionType?: string;
  voucherCap?: number;
  percentageDiscount?: number;
  buy_x?: number;
  get_y?: number;
};

const ImageSkeleton: React.FC = () => (
  <div className="animate-pulse bg-zinc-300 rounded-sm h-[250px] w-full"></div>
);

const Rounder: FunctionComponent<Offer> = ({
  id,
  images = [],
  name = "",
  longitude = 0,
  latitude = 0,
  categories = [],
  price = 0,
  description = "",
  promotionType,
  percentageDiscount,
  buy_x,
  get_y
}) => {
  const navigate = useNavigate();
  const [likedOffers, setLikedOffers] = useState<string[]>([]);
  const [imageLoaded, setImageLoaded] = useState(false);

  const fetchLikedOffers = useCallback(async () => {
    const jwtToken = localStorage.getItem('jwtToken');
  
    if (!jwtToken) {
      console.error('No JWT token found');
      return;
    }
  
    try {
      const response = await axios.get(
        `${API_BASE_URL}/v1/users/customer/liked-offers`,
        {
          params: {
            limit: 100,
            page: 1
          },
          headers: {
            'Authorization': `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (response.status === 200) {
        const likedOfferIds = response.data
          .filter((offer: any) => offer.interactionType === "BUSINESS_LIKED")
          .map((offer: any) => offer.offerId);
        setLikedOffers(likedOfferIds);
      }
    } catch (error) {
      console.error('Error fetching liked offers:', error);
    }
  }, []);

  // useEffect(() => {
  //   fetchLikedOffers();
  // }, [fetchLikedOffers]);

  const isLiked = useMemo(() => likedOffers.includes(id), [likedOffers, id]);

  const parsedLatitude = localStorage.getItem('userLatitude');
  const parsedLongitude = localStorage.getItem('userLongitude');
  const latUser = parsedLatitude ? parseFloat(parsedLatitude) : undefined;
  const longUser = parsedLongitude ? parseFloat(parsedLongitude) : undefined;

  function calculateUserDistance(lat1: number, lon1: number): number {
    if (!latUser || !longUser)
      return 0;
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = (latUser - lat1) * Math.PI / 180;
    const dLon = (longUser - lon1) * Math.PI / 180;
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(latUser * Math.PI / 180) * 
      Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const distance = R * c; // Distance in kilometers
    const distanceMiles = distance * 0.621371;
    return Math.floor(distanceMiles * 10) / 10; //round down to 1 decimal place
  }

  const handleActivityClick = () => {
    navigate(`/offer/${id}`)
  };

  //TODO: Implement API endpoint to persist liked Status
  const handleLikeClick = async () => {
    const jwtToken = localStorage.getItem('jwtToken');
  
    if (!jwtToken) {
      console.error('No JWT token found');
      return;
    }
  
    try {
      const response = await axios.post(
        `${API_BASE_URL}/v1/users/customer/interactions`,
        {
          interactionType: isLiked ? 'OFFER_UNLIKED' : 'OFFER_LIKED',
          offerId: id
        },
        {
          headers: {
            'Authorization': `Bearer ${jwtToken}`,
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (response.status === 201) {
        // Instead of directly setting isLiked, fetch the updated status
        // await fetchLikedOffers();
      } else {
        console.error('Failed to update like status');
      }
    } catch (error) {
      console.error('Error updating like status:', error);
    }
  };

  function CategoriesDisplay(categories: string[] | undefined) {
    return (
      <div>
        {categories && categories.length > 0 && categories.join(" • ")}
      </div>
    );
  }
  
  useEffect(() => {
    const img = new Image();
    img.src = images[0] || "";
    img.onload = () => setImageLoaded(true);
    return () => {
      img.onload = null; // Clean up to avoid memory leaks
    };
  }, [images]);
  
  return (
    <div className="py-2 self-stretch w-[285px] shrink-0 flex flex-col items-start justify-start box-border text-left text-base text-copy font-subhead relative">
      <div className="self-stretch flex-1 flex flex-col items-start justify-start z-[1] relative">
        <div className="relative w-full h-[250px]">
          {!imageLoaded && <ImageSkeleton />}
          <img
            className={`absolute top-0 left-0 w-full h-full object-cover rounded-sm transition-opacity duration-300 ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
            src={images[0] || ""}
            alt={description}
            onLoad={() => setImageLoaded(true)}
            onClick={handleActivityClick}
          />
        </div>
        <img
          className="hidden absolute top-0 right-0 mt-1.5 mx-1 h-9 w-9 cursor-pointer"
          alt=""
          src={isLiked ? "/icon--heart1.svg" : "/icon--heart.svg"}
          onClick={handleLikeClick}
        />
        <div className="self-stretch flex flex-col items-start justify-start py-2 px-0 gap-[8px]">
          <div
            className="relative text-xl tracking-[-0.01em] font-semibold text-main-teal cursor-pointer mq450:text-base"
            onClick={handleActivityClick}
          >
            {name}
          </div>
          <div className="self-stretch relative font-medium whitespace-pre-wrap">
            {CategoriesDisplay(categories)}
          </div>
          {localStorage.getItem('userLatitude') && localStorage.getItem('userLongitude') &&
          <div className="self-stretch relative [text-decoration:underline] font-medium">
            {calculateUserDistance(latitude, longitude)} miles away
          </div>}

          {/* RegularOffer */}
          {!promotionType && price > 0 &&
            <div className="w-[187px] flex flex-row items-start justify-start gap-[8px] text-left text-5xl text-main-teal">
              <b className="flex-1 relative tracking-[-0.01em] inline-block font-base min-w-[94px] whitespace-nowrap mq450:text-lgi">
                ${price.toFixed(2)}
              </b>
            </div>
          }

          {/* Percentage Discount */}
          {price > 0 && promotionType === "PERCENTAGE_DISCOUNT" && percentageDiscount &&
            <div className="w-[287px] flex flex-row items-start justify-start gap-[8px] text-left text-5xl text-main-teal">
              <div className="pr-1 relative [text-decoration:line-through] tracking-[-0.01em] inline-block w-auto max-w-[95px] min-w-[65px] whitespace-nowrap mq450:text-lgi">
                ${price.toFixed(2)}
              </div>
              <b className="flex-1 relative tracking-[-0.01em] inline-block text-green min-w-[94px] whitespace-nowrap mq450:text-lgi">
                ${(price * (1 - percentageDiscount*0.01)).toFixed(2)}
              </b>
            </div>
          }

          {/* BUY X GET Y FREE */}
          {price > 0 && promotionType === "BUY_X_GET_Y_FREE" &&
            <div className="w-[187px] flex flex-row items-start justify-start gap-[8px] text-left text-5xl text-main-teal">
              <div className="pr-0 relative text-green tracking-[-0.01em] inline-block whitespace-nowrap mq450:text-lgi font-bold w-auto">
                ${price.toFixed(2)}
              </div>
              <div className="pr-1 relative tracking-[-0.01em] inline-block min-w-[65px] whitespace-nowrap font-semibold mq450:text-lgi">
                Buy {buy_x} get {get_y} free
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default Rounder;