import React, { useState, useEffect } from 'react'
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet'
import { useLocation, Link } from 'react-router-dom';
import { LatLngExpression } from 'leaflet'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css'
import MiniRounder from './MiniRounder';

interface Offer {
  id: string;
  name: string;
  images: string[];
  price: number;
  latitude: number;
  longitude: number;
  categories: string[];
}

interface MapProps {
  offers: Offer[]
  setHeight?: string
  setZoom?: number
  setLat?: number
  setLng?: number
  centerLat?: number
  centerLng?: number
}

let DefaultIcon = L.icon({
  iconUrl: "/marker-dyw.png",
  iconSize: [45, 45],
  iconAnchor: [12, 41],
  popupAnchor: [11, -50],
});

const UserLocationIcon = L.icon({
  iconUrl: "/user-marker.png",
  iconSize: [45, 45],
  iconAnchor: [15, 15],
  popupAnchor: [8, -15],
});

L.Marker.prototype.options.icon = DefaultIcon;

const Map: React.FC<MapProps> = ({ offers, setHeight, setZoom, setLat, setLng, centerLat, centerLng}) => {
  const [userLocation, setUserLocation] = useState<LatLngExpression | null>(null);
  const [locationPermission, setLocationPermission] = useState<boolean>(false);
  const center: LatLngExpression = centerLat && centerLng ? [centerLat, centerLng] : [setLat || 26.6406, setLng || -81.8723];
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted") {
          setLocationPermission(true);
          getUserLocation();
        } else if (result.state === "prompt") {
          setLocationPermission(false);
        }
      });
    }
  }, []);

  const getUserLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setUserLocation([latitude, longitude]);
        localStorage.setItem('userLatitude', latitude.toString());
        localStorage.setItem('userLongitude', longitude.toString());
      },
      (error) => {
        console.error("Error getting user location:", error);
      }
    );
  };

  const handleLocationPermission = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation([latitude, longitude]);
          setLocationPermission(true);
          localStorage.setItem('userLatitude', latitude.toString());
          localStorage.setItem('userLongitude', longitude.toString());
        },
        (error) => {
          console.error("Error getting user location:", error);
          setLocationPermission(false);
        }
      );
    }
  };

  function calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const R = 6371;
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
      Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const distance = R * c;
    return distance * 0.621371;
  }

  function MapView() {
    const map = useMap();
    useEffect(() => {
      if (offers.length > 0 && offers[0].latitude && offers[0].longitude) {
        map.setView([offers[0].latitude, offers[0].longitude], setZoom || 13);
      } else if (centerLat && centerLng) {
        map.setView([centerLat, centerLng], setZoom || 13);
      } else if (userLocation) {
        map.setView(userLocation as LatLngExpression, setZoom || 13);
      }
    }, [centerLat, centerLng, userLocation, offers, setZoom]);
    return null;
  }

  return (
    <div>
      {!locationPermission && (
        <button onClick={handleLocationPermission} className="cursor-pointer my-2 p-2 bg-blue-500 text-white rounded">
          Allow Location Access
        </button>
      )}
      {/* {offers.length > 0 ? ( */}
        <MapContainer
          center={center}
          zoom={setZoom || 13}
          scrollWheelZoom={true}
          style={{ height: setHeight || '500px', width: '100%', zIndex: 1 }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          
          {currentPath !== "/map" && !currentPath.includes("/offer") && <MapView />}

          {locationPermission && userLocation && (
            <Marker position={userLocation as LatLngExpression} icon={UserLocationIcon}>
              <Popup closeButton={false} className=''>
                <div className="pl-2.5 font-semibold font-subhead">My Location</div>
              </Popup>
            </Marker>
          )}
          {offers.map((offer) => {
            if (!offer.latitude || !offer.longitude) return null;

            const distance = userLocation
              ? calculateDistance(
                  Array.isArray(userLocation) ? userLocation[0] : userLocation.lat,
                  Array.isArray(userLocation) ? userLocation[1] : userLocation.lng,
                  offer.latitude,
                  offer.longitude
                )
              : null;
            
            return (
              <Marker 
                key={offer.id} 
                position={[offer.latitude, offer.longitude]} 
                icon={DefaultIcon}
              >
                <Popup closeButton={false} className='my-0 py-0'>
                  <MiniRounder
                    coverImage={offer.images && offer.images.length > 0 ? offer.images[0] : ""}
                    activityTitle={offer.name}
                    priceNew={offer.price}
                    activityLink={`/offer/${offer.id}`}
                    categories={offer.categories || []}
                    distance={distance !== null ? `${distance.toFixed(1)}` : "N/A"}
                  />
                </Popup>
              </Marker>
            );
          })}
        </MapContainer>
      {/* ) : (
        <div>No offers available in this area.</div>
      )} */}
    </div>
  )
}

export default Map