import { FunctionComponent, useCallback, useEffect, useState } from "react";
import LikedItem from "../../components/User/LikedItem";
import LikedItemMobile from "../../components/Mobile/LikedItemMobile";
import Header from "../../components/User/Header";
import UserFooter from "../../components/User/UserFooter";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

interface LikedItemInteraction {
  id: string;
  customerId: string;
  interactionType: string;
  offerId: string;
}

interface Offer {
  id: string;
  name: string;
  description: string;
  displayImageReferences: string[];
  // Add other properties as needed
}

const H6ItineraryPage: FunctionComponent = () => {
  const [likedItems, setLikedItems] = useState<LikedItemInteraction[]>([]);
  const [likedOffers, setLikedOffers] = useState<Offer[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLikedItems = async () => {
      try {
        const jwtToken = localStorage.getItem("jwtToken");
        if (!jwtToken) {
          throw new Error('No JWT token found');
        }
  
        const response = await axios.get(`${API_BASE_URL}/v1/users/customer/liked-offers`, {
          headers: {
            'Authorization': `Bearer ${jwtToken}`,
          }
        });
  
        setLikedItems(response.data);
      } catch (error) {
        console.error('Error fetching liked items:', error);
      }
    };

    // fetchLikedItems(); //LIKE
  }, []);

  useEffect(() => {
    const fetchOfferDetails = async () => {
      if (likedItems.length === 0) return;

      const jwtToken = localStorage.getItem("jwtToken");
      if (!jwtToken) {
        console.error('No JWT token found');
        return;
      }

      try {
        const offerPromises = likedItems.map(item => 
          axios.get(`${API_BASE_URL}/v1/catalog/offers/${item.offerId}`, {
            headers: {
              'Authorization': `Bearer ${jwtToken}`,
            }
          })
        );

        const offerResponses = await Promise.all(offerPromises);
        const offers = offerResponses.map(response => response.data);
        setLikedOffers(offers);
      } catch (error) {
        console.error('Error fetching offer details:', error);
      }
    };

    fetchOfferDetails();
  }, [likedItems]);

  const onButtonClick = useCallback(() => {
    // Please sync "H2.1 Search Results - map open" to the project
  }, []);

  const onBackToItineraryClick = useCallback(() => {
    navigate("/itineraries")
  }, []);

  const onMoreActivitiesClick = useCallback(() => {
    navigate("/map")
  }, []);

  return (
    <>
    <Header />
      {/* Desktop page (>=780px) */}
      <div className="hidden mq780:flex flex-col">
      <section className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-10 box-border max-w-full text-left text-61xl text-white font-subhead">
        <div className="flex-1 flex flex-row items-start justify-start relative max-w-full">
        <img
          className="w-full h-auto object-cover max-h-[35vh]"
          src="/family-on-beach.jpg"
          alt="Happy Family Picture"
          loading="lazy"
        />
        <div className="absolute inset-0 bg-black bg-opacity-20" />
          <h1 className="absolute bottom-4 left-20 text-61xl text-white font-bold z-10 -mb-0">
            My Likes
          </h1>
        </div>
      </section>
      <section className="flex flex-col gap-0">
          {likedOffers.length > 0 ? (
            likedOffers.map((offer) => (
              <LikedItem 
                id={offer.id}
                title={offer.name}
                image={offer.displayImageReferences[0]}
                description={offer.description}
              />
            ))
          ) : (
            <div className="py-8 text-11xl font-subhead text-main-teal w-full items-center text-center justify-center">No Liked items yet</div>
          )}
      </section>
      <div className="gap-10 self-stretch flex flex-row items-start justify-center py-20 px-5 box-border max-w-full">
        <button
          className="cursor-pointer [border:none] pt-[19.3px] px-[42px] pb-[20.7px] bg-[transparent] flex flex-row items-start justify-start box-border relative whitespace-nowrap max-w-full mq450:pl-5 mq450:pr-5 mq450:box-border"
          onClick={onButtonClick}
        >
          <div className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-md bg-main-teal" />
          <b onClick={onMoreActivitiesClick} className="h-[30px] w-[257px] relative text-6xl tracking-[-0.01em] flex font-subhead text-white text-center items-center justify-center z-[1]">
            Add More Activities
          </b>
        </button>
        <button
          className="cursor-pointer [border:none] pt-[19.3px] px-[42px] pb-[20.7px] bg-[transparent] flex flex-row items-start justify-start box-border relative whitespace-nowrap max-w-full mq450:pl-5 mq450:pr-5 mq450:box-border"
          onClick={onButtonClick}
        >
          <div className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-md bg-white border border-solid border-main-teal" />
          <b onClick={onBackToItineraryClick} className="h-[30px] w-[257px] relative text-6xl tracking-[-0.01em] flex font-subhead text-main-teal text-center items-center justify-center z-[1]">
            Back to Itinerary
          </b>
        </button>
      </div>
      </div>

      {/* Mobile page (<780px) */}
      <div className="mq780:hidden pt-[73px]">
      <section className="pb-30 min-h-[80vh] self-stretch bg-white-100 flex flex-col items-start justify-start relative max-w-full text-center text-29xl text-main-teal font-subhead">
          <p className="pl-6 bg-white text-start font-bold font-subhead m-0 py-4 text-10xl">My Likes</p>
          {likedOffers.length > 0 ? (
            likedOffers.map((offer) => (
              <LikedItemMobile 
                id={offer.id}
                title={offer.name}
                image={offer.displayImageReferences[0]}
                description={offer.description}
              />
            ))
          ) : (
            <>
            <div className="py-8 mt-[15vh] text-11xl min-h-[20vh] font-subhead text-main-teal w-full items-center text-center justify-center">No Liked items yet</div>
              <div className="gap-5 self-stretch flex flex-col items-start justify-center py-20 px-5 box-border max-w-full">
              <button
                className="cursor-pointer [border:none] pt-[19.3px] px-[42px] pb-[20.7px] bg-[transparent] flex flex-row items-start justify-start box-border relative whitespace-nowrap max-w-full mq450:pl-5 mq450:pr-5 mq450:box-border"
                onClick={onButtonClick}
              >
                <div className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-md bg-main-teal" />
                <b onClick={onMoreActivitiesClick} className="h-[30px] w-[90vw] relative text-xl tracking-[-0.01em] flex font-subhead text-white text-center items-center justify-center z-[1]">
                  Add More Activities
                </b>
              </button>
              <button
                className="cursor-pointer [border:none] pt-[19.3px] px-[42px] pb-[20.7px] bg-[transparent] flex flex-row items-start justify-start box-border relative whitespace-nowrap max-w-full mq450:pl-5 mq450:pr-5 mq450:box-border"
                onClick={onButtonClick}
              >
                <div className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-md bg-white border border-solid border-main-teal" />
                <b onClick={onBackToItineraryClick} className="h-[30px] w-[90vw] relative text-xl tracking-[-0.01em] flex font-subhead text-main-teal text-center items-center justify-center z-[1]">
                  Back to Itineraries
                </b>
              </button>
            </div>
            </>
          )}
        </section>
      </div>
      <UserFooter
        dYWLogoW1="/dyw-logow-1@2x.png"
        propAlignSelf="stretch"
        propPosition="unset"
        propTop="unset"
        propLeft="unset"
        propWidth="unset"
      />
    </>
  );
};

export default H6ItineraryPage;
