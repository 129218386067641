import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CarAnimation from './CarAnimation';

interface QuizComponent3 {
  onInputClick?: () => void;
}

function QuizComponent3({ onInputClick }: QuizComponent3) {

    const navigate = useNavigate();

    const onPrevClick = () => {
        navigate("/h72-quiz-page");
    };

    const onNextClick = () => {
        navigate("/h75-quiz-page");
    };

    const onExitClick = () => {
        navigate("/");
    };

    const [IsSelected1, setIsSelected1] = useState(false);
    const [IsSelected2, setIsSelected2] = useState(false);
    const [IsSelected3, setIsSelected3] = useState(false);
    const [IsSelected4, setIsSelected4] = useState(false);

    const onItem1Click = () => {
        setIsSelected1(!IsSelected1);
      };

    const onItem2Click = () => {
      setIsSelected2(!IsSelected2);
    };

    const onItem3Click = () => {
      setIsSelected3(!IsSelected3);
    };

    const onItem4Click = () => {
      setIsSelected4(!IsSelected4);
    };

  return (
    <div className="flex flex-col items-center px-5 min-h-screen">
      <CarAnimation percentage='45%' />
      <div className="pt-2 text-xl font-semibold tracking-tight text-center text-zinc-800">
        STEP 3 OF 6
      </div>
      <div className="mt-16 mb-0 text-19xl font-semibold text-center leading-[54.4px] text-zinc-800 max-md:mt-5 max-md:max-w-full max-md:text-4xl">
        Does your Trip include:
      </div>
      <div className="mt-2 mb-10 text-xl font-medium leading-8 text-center text-zinc-500 w-[70%] max-md:max-w-full 580px:text-sm 580px:leading-6 580px:w-[90vw]">
        Select all that apply!
      </div>
      <div className='flex flex-wrap w-full justify-between gap-5 items-center h-auto max-w-[90vw] mq780:flex-row'>
        <button onClick={onItem1Click} className={`flex-grow flex-shrink text-6xl text-inherit py-6 px-4 cursor-pointer rounded-md text-center border-2 border-solid border-round border-zinc-500
          ${IsSelected1 ? 'bg-blue-500 text-white' : 'bg-white text-blue-900'}`}>
          Kids
        </button>
        <button onClick={onItem2Click} className={`flex-grow flex-shrink text-6xl text-inherit py-6 px-4 cursor-pointer rounded-md text-center border-2 border-solid border-round border-zinc-500 
          ${IsSelected2 ? 'bg-blue-500 text-white' : 'bg-white text-blue-900'}`}>
          Pets
        </button>
        <button onClick={onItem3Click} className={`flex-grow flex-shrink text-6xl text-inherit py-6 px-4 cursor-pointer rounded-md text-center border-2 border-solid border-round border-zinc-500 
          ${IsSelected3 ? 'bg-blue-500 text-white' : 'bg-white text-blue-900'}`}>
          Disability
        </button>
      </div>
      <div className= "flex gap-5 justify-between self-stretch mt-16 w-full text-2xl font-bold tracking-tight text-center whitespace-nowrap max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
        <button
          className="cursor-pointer justify-center px-7 py-4 rounded-lg border text-base font-bold bg-white font-subhead text-blue-900 border-blue-900 border-solid max-md:px-5"
          onClick={onExitClick}
        >
          Exit
        </button>
        <div className="bg-white">
          <button onClick={onPrevClick} className="cursor-pointer bg-white">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/2021e9ccc88ebc65db830d5a9d2a35d821d8e749de6bfa92c6afc42a38a6b646?apiKey=0d607e805d95499aa95fa0a93ed5f083&" alt="" className="shrink-0 aspect-[1.39] w-[81px]" />
          </button>
          <button onClick={onNextClick} className="cursor-pointer bg-white">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c1e1114e9b232c385299242371a03e522235c9d963adfca02707c077051d049d?apiKey=0d607e805d95499aa95fa0a93ed5f083&" className="shrink-0 w-20 aspect-[1.41]" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default QuizComponent3;
