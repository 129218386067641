import { FunctionComponent, useCallback } from "react";
import LikedItem from "../../components/User/LikedItem";
import LikedItemMobile from "../../components/Mobile/LikedItemMobile";
import Header from "../../components/User/Header";
import UserFooter from "../../components/User/UserFooter";
import { Navigate, useNavigate } from "react-router-dom";

const QuizResult: FunctionComponent = () => {

  const navigate = useNavigate();

  const onButtonClick = useCallback(() => {
    // Please sync "H2.1 Search Results - map open" to the project
  }, []);

  const onBackToItineraryClick = useCallback(() => {
    navigate("/itineraries")
  }, []);

  const onMoreActivitiesClick = useCallback(() => {
    navigate("/map")
  }, []);

  return (
    <>
    <Header />
      {/* Desktop page (>=780px) */}
      <div className="hidden mq780:flex flex-col">
      <section className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-10 box-border max-w-full text-left text-61xl text-white font-subhead">
        <div className="flex-1 flex flex-row items-start justify-start relative max-w-full">
        <img
          className="w-full h-auto object-cover max-h-[35vh]"
          src="/top-image@2x.png"
          alt="Happy Family Picture"
          loading="lazy"
        />
        <div className="absolute inset-0 bg-black bg-opacity-20" />
          <h1 className="absolute bottom-4 left-20 text-21xl mq1050:text-41xl lg:text-61xl text-white font-bold z-10 -mb-0">
            Our Recommendations
          </h1>
        </div>
      </section>
      <section className="flex flex-col gap-0">
        <LikedItem 
          id="829Sd35328hf32r2rh3ip23"
          title="Sample Offer"
          image="/apple-picking@2x.png"
          description="Lorem ipsum dolor sit amet"
        />
        <LikedItem 
          id="829Sd35328hf32r2rh3ip23"
          title="Sample Offer"
          image="/apple-picking@2x.png"
          description="Lorem ipsum dolor sit amet"
        />
      </section>
      <div className="gap-10 self-stretch flex flex-row items-start justify-center py-20 px-5 box-border max-w-full">
        <button
          className="cursor-pointer [border:none] pt-[19.3px] px-[42px] pb-[20.7px] bg-[transparent] flex flex-row items-start justify-start box-border relative whitespace-nowrap max-w-full mq450:pl-5 mq450:pr-5 mq450:box-border"
          onClick={onButtonClick}
        >
          <div className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-md bg-main-teal" />
          <b onClick={onMoreActivitiesClick} className="h-[30px] w-[257px] relative text-6xl tracking-[-0.01em] flex font-subhead text-white text-center items-center justify-center z-[1]">
            Add More Activities
          </b>
        </button>
        <button
          className="cursor-pointer [border:none] pt-[19.3px] px-[42px] pb-[20.7px] bg-[transparent] flex flex-row items-start justify-start box-border relative whitespace-nowrap max-w-full mq450:pl-5 mq450:pr-5 mq450:box-border"
          onClick={onButtonClick}
        >
          <div className="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-md bg-white border border-solid border-main-teal" />
          <b onClick={onBackToItineraryClick} className="h-[30px] w-[257px] relative text-6xl tracking-[-0.01em] flex font-subhead text-main-teal text-center items-center justify-center z-[1]">
            Back to Itinerary
          </b>
        </button>
      </div>
      </div>

      {/* Mobile page (<780px) */}
      <div className="mq780:hidden pt-[73px]">
        <section className="pb-30 self-stretch bg-white-100 flex flex-col items-start justify-start  relative max-w-full text-center text-29xl text-main-teal font-subhead">
          <p className="pl-6 bg-white text-center font-bold text-zinc-800 font-subhead m-0 py-4 pb-1 text-5xl mq480:text-11xl">Our Recommendations</p>
          <p className="pl-6 bg-white text-center font-base text-zinc-500 font-subhead m-0 pb-4 text-base mq480:text-xl">Based on your choices</p>
          <LikedItemMobile
            id="dsfk43r932f9fjw"
            title="Kid Friendly Farm"
            image="kidfriendly-farm@2x.png"
            description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specific took a galley of type and scrambled it to make a type specific"
          />
          <LikedItemMobile
            id="dsfk43r932f9fjw"
            title="Great Sanibel Apple Picking"
            image="apple-picking@2x.png"
            description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specific took a galley of type and scrambled it to make a type specific"
          />
          <LikedItemMobile
            id="dsfk43r932f9fjw"
            title="Kid UNFriendly Farm"
            image="kidfriendly-farm@2x.png"
            description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specific took a galley of type and scrambled it to make a type specific"
          />
        </section>
        <div className="w-full flex justify-center items-center pt-10 pb-20">
            <button 
                className="w-[250px] cursor-pointer [border:none] py-[20px] px-6 bg-[transparent] flex flex-row items-center justify-center relative whitespace-nowrap z-[1]"
                onClick={() => navigate("/map")}
                >
                <div className="h-full w-full absolute !m-[0] top-[0px] right-[1px] bottom-[0px] left-[0px] rounded-md bg-[#479abf] box-border border-[1px] border-solid border-[#479abf]" />
                <b className="w-[167px] relative text-lg tracking-[-0.01em] leading-[19px] inline-block font-subhead text-zinc-50 hover:text-white text-center z-[1]">
                    View All Offers
                </b>
            </button>
        </div>
      </div>
      <UserFooter
        dYWLogoW1="/dyw-logow-1@2x.png"
        propAlignSelf="stretch"
        propPosition="unset"
        propTop="unset"
        propLeft="unset"
        propWidth="unset"
      />
    </>
  );
};

export default QuizResult;
