import { FunctionComponent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/User/Header";
import UserFooter from "../../../components/User/UserFooter";
import { ArrowLeft } from "lucide-react";
import SideNavUserProfile from "../../../components/User/SideNavUserProfile";
import BookingItem from "../../../components/User/BookingItem";
import { useWindowWidth } from "../../../hooks/useWindowWidth";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

interface Booking {
  offerID: string;
  orderDate: string;
  title: string;
  image: string;
  description: string;
}

const Bookings: FunctionComponent = () => {
  const navigate = useNavigate();
  const [hasBookings, setHasBookings] = useState(false);
  const [upcomingBookings, setUpcomingBookings] = useState<Booking[]>([]);
  const [pastBookings, setPastBookings] = useState<Booking[]>([]);
  const width = useWindowWidth();

  const handleBackClick = () => {
    navigate("/account");
  };

  const handleExploreClick = () => {
    navigate("/map");
  };

  const fetchBookings = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/v1/orders/customer`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
      });
  
      const orders = response.data;
      const upcoming: Booking[] = [];
      const past: Booking[] = [];
  
      for (const order of orders) {
        for (const item of order.items) {
          try {
            // Fetch offer details
            const offerResponse = await axios.get(`${API_BASE_URL}/v1/catalog/offers/${item.offerId}`, {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
              }
            });
            
            const offerData = offerResponse.data;
            
            //ensure only paid offers are listed here
            if(offerResponse.data.price > 0) {
              const booking: Booking = {
                offerID: item.offerId,
                orderDate: new Date(order.createdAt).toLocaleDateString('en-US', { month: 'long', day: 'numeric' }),
                title: offerData.name || 'Unknown Offer',
                image: offerData.displayImageReferences[0] || '/default-image.png',
                description: offerData.description || 'No description available',
              };
  
              if (item.qrCodeScanned) {
                past.push(booking);
              } else {
                upcoming.push(booking);
              }
            }
          } catch (error) {
            console.error(`Error fetching offer details for offerId ${item.offerId}:`, error);
            // If offer fetch fails, use fallback data
            const fallbackBooking: Booking = {
              offerID: item.offerId,
              orderDate: new Date(order.createdAt).toLocaleDateString('en-US', { month: 'long', day: 'numeric' }),
              title: 'Unknown Offer',
              image: '/default-image.png',
              description: 'No description available',
            };
            if (item.qrCodeScanned) {
              past.push(fallbackBooking);
            } else {
              upcoming.push(fallbackBooking);
            }
          }
        }
      }
  
      setUpcomingBookings(upcoming);
      setPastBookings(past);
      setHasBookings(upcoming.length > 0 || past.length > 0);
    } catch (error) {
      console.error('Error fetching bookings:', error);
    }
  };

  useEffect(() => {
    fetchBookings();
  }, []);

  return (
    <div className="flex flex-col min-h-screen font-subhead">
      {width >= 780 ? (
        <>
          <Header />
          <div className="flex flex-1">
            <SideNavUserProfile activeItem="My Bookings" />
            <div className="flex-1 bg-gray-100 p-6">
              {hasBookings ? (
                <div className="bg-white rounded-lg p-6">
                  <h2 className="text-2xl font-semibold text-blue-900 mb-6">Upcoming Bookings</h2>
                  {upcomingBookings.map((booking, index) => (
                    <BookingItem key={index} {...booking} />
                  ))}
                  <h2 className="text-2xl font-semibold text-blue-900 mt-10 mb-6">Past Bookings</h2>
                  {pastBookings.map((booking, index) => (
                    <BookingItem key={index} {...booking} />
                  ))}
                </div>
              ) : (
                <NoBookingsContent onExploreClick={handleExploreClick} />
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="flex flex-col h-full">
          <div className="flex justify-between items-center px-4 py-3 border-b">
            <button onClick={handleBackClick} className="bg-white text-gray-500">
              <ArrowLeft className="bg-white w-6 h-6" />
            </button>
            <h2 className="text-xl font-semibold">Bookings</h2>
            <div className="w-6" />
          </div>
          {hasBookings ? (
            <div className="flex-1 overflow-y-auto">
              <h3 className="text-3xl text-blue-800 font-semibold px-4 py-2 bg-gray-100">Upcoming Bookings</h3>
              {upcomingBookings.map((booking, index) => (
                <BookingItem key={index} {...booking} isMobile />
              ))}
              <h3 className="text-5xl text-blue-800 font-semibold px-4 py-2 bg-gray-100 mb-6 mt-20">Past Bookings</h3>
              {pastBookings.map((booking, index) => (
                <BookingItem key={index} {...booking} isMobile />
              ))}
            </div>
          ) : (
            <NoBookingsContent onExploreClick={handleExploreClick} />
          )}
        </div>
      )}
      <UserFooter
        dYWLogoW1="/dyw-logow-1@2x.png"
        propAlignSelf="stretch"
        propPosition="unset"
        propTop="unset"
        propLeft="unset"
        propWidth="unset"
      />
    </div>
  );
};

const NoBookingsContent: FunctionComponent<{ onExploreClick: () => void }> = ({ onExploreClick }) => (
  <div className="flex flex-col items-center justify-center flex-1 p-6 pb-48">
    <p className="font-subhead text-blue-900 font-semibold text-2xl mb-4">No Bookings Yet!</p>
    <img
      src="/no-bookings-animation.gif"
      className="w-auto h-64 mb-6"
      alt="No bookings animation"
    />
    <p className="font-subhead text-blue-900 font-semibold text-xl mb-4">Let's Change that!</p>
    <button
      onClick={onExploreClick}
      className="cursor-pointer w-48 py-3 text-xl rounded-lg border border-[#478abf] text-[#478abf] font-subhead font-semibold bg-white hover:bg-[#478abf] hover:text-white transition-colors"
    >
      Explore Offers
    </button>
  </div>
);

export default Bookings;