// SideNavUserProfile.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface SideNavUserProfileProps {
  activeItem: string;
}

const SideNavUserProfile: React.FC<SideNavUserProfileProps> = ({ activeItem }) => {
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  const menuItems = [
    { name: 'Profile', path: '/account/profile' },
    { name: 'My Bookings', path: '/account/bookings' },
    // { name: 'Preferences', path: '/account/notification-settings' },
    // { name: 'Payment Methods', path: '/account/payments' },
    { name: 'Contact Support', path: '/account/support' },
    { name: 'Privacy', path: '/account/privacy' },
    { name: 'Close Account', path: '/account/close-account' },
  ];

  const onSupport = () => {
    window.location.href = `mailto:andrew@doyouweekend.com?subject=USER%20SUPPORT%20REQUEST`;
  };

  return (
    <div className="w-full md:w-[300px] lg:w-[30vw] max-w-[330px] bg-whitesmoke flex flex-col pl-10 pt-[60px] pb-[40vh] min-h-screen px-4 box-border gap-[30px] text-left text-xl font-semibold text-[#478abf] font-subhead">
      {menuItems.map((item) => (
        <button 
          key={item.name}
          className={`w-[80%] cursor-pointer font-subhead text-left text-base font-semibold py-2 px-4 rounded-md ${
            activeItem === item.name 
              ? 'bg-[#478abf] text-white' 
              : 'bg-transparent hover:bg-zinc-200'
          }`}
          onClick={item.name == "Contact Support" ? onSupport : () => handleNavigation(item.path)}
        >
          {item.name}
        </button>
      ))}
      <button 
        className="w-[50%] ml-10 cursor-pointer mt-4 py-2 px-4 bg-white text-[#478abf] border border-[#478abf] rounded-md hover:bg-[#478abf] font-semibold hover:text-white transition-colors"
        onClick={() => handleNavigation('/signout')}
      >
        Sign Out
      </button>
    </div>
  );
};

export default SideNavUserProfile;