import React, { FunctionComponent, useEffect, useState } from "react";
import MerchantHubHeader from "../../components/Merchant/MerchantHubHeader";
import Footer from "../../components/Merchant/MerchantFooter";
import SideNavPost from "../../components/Merchant/SideNavPost";
import { useNavigate, useParams } from "react-router-dom";
import CompanyCard from "../../components/Merchant/CompanyCard";
import { ChevronLeft } from "lucide-react";
import ReviewPost from "../../components/Merchant/ReviewPost";
import { DateTime } from "luxon";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api';

interface Highlight {
  id: string;
  name: string;
}

const MerchantHubPost3: FunctionComponent = () => {
  const navigate = useNavigate();
  const { offerId } = useParams<{ offerId?: string }>();
  const [images, setImages] = useState<String[]>();
  const [promotionData, setPromotionData] = useState<any | null>(null);
  const [offerData, setOfferData] = useState<any>(null);
  const [promoId, setPromoId] = useState<String | null>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});
  
  const fetchPromotionData = async (offerId: string) => {
    // if(localStorage.getItem("promotionData"))
    //     return localStorage.getItem("promotionData");

    try {
      const response = await axios.get(`${API_BASE_URL}/v1/promotions/by-offer/${offerId}`, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching promotion data:', error);
      return null;
    }
  };

  const getPromotionId = async (offerId: string) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/v1/promotions/by-offer/${offerId}`, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` }
      });
      if (response.data && response.data.length > 0) {
        return response.data[response.data.length - 1].id;
      }
      return null;
    } catch (error) {
      console.error('Error fetching promotion data:', error);
      return null;
    }
  };


  useEffect(() => {
    const savedOfferData = localStorage.getItem('offerData');
    const savedPromotionData = localStorage.getItem('promotionData');
    const savedImages = localStorage.getItem('uploadedImages');
    console.log("Logged promotionData: ", savedPromotionData);
  
    const fetchData = async () => {
      if (offerId) {
        try {
          // Fetch offer data
          if (savedOfferData) {
            const parsedOfferData = JSON.parse(savedOfferData);
            // console.log("(----------parsedOfferData",parsedOfferData)
            setOfferData(parsedOfferData);
            // console.log("Set offerdata from localStorage:", parsedOfferData);
          } else {
            const offerResponse = await axios.get(`${API_BASE_URL}/v1/catalog/offers/${offerId}`, {
              headers: { 'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` }
            });
            setOfferData(offerResponse.data);
            // console.log("Set offerdata from API:", offerResponse.data);
          }
  
          // Handle promotion data
          if (savedPromotionData) {
            const parsedPromotionData = JSON.parse(savedPromotionData);
            setPromotionData(parsedPromotionData);
          } else {
            const fetchedPromotionData = await fetchPromotionData(offerId);
            if (fetchedPromotionData && fetchedPromotionData.length > 0) {
              const promotionWithId = { ...fetchedPromotionData[0], id: await getPromotionId(offerId) };
              console.log("Promotion Fetched")
              setPromotionData(promotionWithId);
            }
          }

          // Automatically call getPromotionId when offerId is present
          if (offerId) {
            const promotionId = await getPromotionId(offerId);
            setPromoId(promotionId);
          }
  
          // Set images
          if (savedImages) {
            const parsedImages = JSON.parse(savedImages);
            setImages(parsedImages);
            // console.log("Set Images from localStorage");
          } else {
            setImages(offerData.images || []);
            // console.log("Set Images from offerData");
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      } else {
        if (savedOfferData) {
          const parsedOfferData = JSON.parse(savedOfferData);
          setOfferData(parsedOfferData);
          // console.log("Loaded Offer Data from localStorage:", parsedOfferData);
        }
        if (savedPromotionData) {
          // const parsedPromotionData = JSON.parse(savedPromotionData);
          setPromotionData(savedPromotionData);
          // console.log("Loaded Promotion Data from localStorage:", parsedPromotionData);
        }
      }
    };
    console.log("Set promotionData from localStorage:", promotionData);
    
    fetchData();
  }, [offerId]);

  const onGoBackClick = () => {
    navigate(-1);
  };

  // console.log("promoId :", promoId)
  // console.log("offerData.offer.price :", offerData.offer.price)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setValidationErrors({});
    
    if (!offerData) {
      setValidationErrors({ offerData: 'No offer data available. Please fill in the offer details.' });
      setIsSubmitting(false);
      return;
    }
    
    if (!offerData) {
      console.error('No offer data available');
      return;
    }
  
    // console.log('Offer data before formatting:', offerData);
  
    // Use the offer data directly for updates, or the nested offer for new offers
    let formattedOfferData = offerId ? { ...offerData.offer } : { ...offerData.offer };
  
    // Ensure required fields are present
    formattedOfferData = {
      ...formattedOfferData,
      name: formattedOfferData.name || '',
      description: formattedOfferData.description || '',
      locations: formattedOfferData.locations || [],
      categoryIds: formattedOfferData.categoryIds || [],
      price: formattedOfferData.price || 0,
      tags: formattedOfferData.tags || [],
      highlightIds: formattedOfferData.highlightIds || [],
    };
  
    if (formattedOfferData.recurrencePattern) {
      if (formattedOfferData.recurrencePattern.startTime) {
        formattedOfferData.recurrencePattern.startTime = DateTime.fromISO(formattedOfferData.recurrencePattern.startTime).toUTC().toISO();
      }
      if (formattedOfferData.recurrencePattern.endTime) {
        formattedOfferData.recurrencePattern.endTime = DateTime.fromISO(formattedOfferData.recurrencePattern.endTime).toUTC().toISO();
      }
      if (formattedOfferData.recurrencePattern.startDate) {
        formattedOfferData.recurrencePattern.startDate = DateTime.fromISO(formattedOfferData.recurrencePattern.startDate).toUTC().toISO();
      }
      if (formattedOfferData.recurrencePattern.endDate) {
        formattedOfferData.recurrencePattern.endDate = DateTime.fromISO(formattedOfferData.recurrencePattern.endDate).toUTC().toISO();
      }
    } //TODO: also send promotion in ZonedDateTIme
  
    // console.log("Formatted offer data (before processing):", formattedOfferData);
  
    const formData = new FormData();
    formData.append('offer', new Blob([JSON.stringify(formattedOfferData)], {
      type: 'application/json'
    }));

    let displayedimages = offerId ? images || [] : JSON.parse(localStorage.getItem('uploadedImages') || '[]')
    if (displayedimages.length === 0) {
      formData.append('images', new Blob(['[]'], { type: 'application/json' }));
    } else {
      for (let i = 0; i < displayedimages.length; i++) {
        const image = displayedimages[i];
        if (image.startsWith('data:image')) {
          // For base64 encoded images
          const response = await fetch(image);
          const blob = await response.blob();
          formData.append('images', blob, `image${i}.jpg`);
        } else if (image instanceof Blob) {
          // For Blob objects
          formData.append('images', image, `image${i}.jpg`);
        } else {
          // For image URLs
          formData.append('images', image);
        }
      }
    }
  
    try {
      let offerResponse;
      if (offerId) {
        offerResponse = await axios.put(`${API_BASE_URL}/v1/catalog/offers/${offerId}`, formData, {
          headers: { 
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
            'Content-Type': 'multipart/form-data'
          }
        });
      } else {
        offerResponse = await axios.post(`${API_BASE_URL}/v1/catalog/offers`, formData, {
          headers: { 
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
            'Content-Type': 'multipart/form-data'
          }
        });
      }
  
      // console.log('Offer response:', offerResponse);

      if (promotionData) {
        const promotionPayload = {
          ...promotionData,
          offerIds: [offerResponse.data.id],
          maxUsageCount: promotionData.maxUsageCount || 1000  // Ensure maxUsageCount is set
        };

        if (promotionPayload.recurrencePattern) {
          if (promotionPayload.recurrencePattern.startTime) {
            promotionPayload.recurrencePattern.startTime = DateTime.fromISO(promotionPayload.recurrencePattern.startTime).toUTC().toISO();
          }
          if (promotionPayload.recurrencePattern.endTime) {
            promotionPayload.recurrencePattern.endTime = DateTime.fromISO(promotionPayload.recurrencePattern.endTime).toUTC().toISO();
          }
          if (promotionPayload.recurrencePattern.startDate) {
            promotionPayload.recurrencePattern.startDate = DateTime.fromISO(promotionPayload.recurrencePattern.startDate).toUTC().toISO();
          }
          if (promotionPayload.recurrencePattern.endDate) {
            promotionPayload.recurrencePattern.endDate = DateTime.fromISO(promotionPayload.recurrencePattern.endDate).toUTC().toISO();
          }
        }
      
        // console.log('Promotion payload:', promotionPayload);  // Add this for debugging
        // console.log('Offer payload:', offerData);  // Add this for debugging
      
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
          'Content-Type': 'application/json'
        };

        if (promotionData.discountValue) {
          try {
            console.log("promotionData :", promotionData)
            console.log("promoId :", promoId)
            if (offerId && promoId) { //promotionData.id
              await axios.put(`${API_BASE_URL}/v1/promotions/${promoId}`, promotionPayload, { headers });
            } else {
              await axios.post(`${API_BASE_URL}/v1/promotions`, promotionPayload, { headers });
            }
          } catch (error) {
            console.error('Error submitting promotion:', error);
            if (axios.isAxiosError(error) && error.response) {
              console.error('Response data:', error.response.data);
            }
          }
        }
      };

      localStorage.removeItem('offerData');
      localStorage.removeItem('uploadedImages');
      localStorage.removeItem('promotionData');
      navigate('/merchant-hub-deals');
    } catch (error) {
      console.error('Error submitting offer/promotion:', error);
      if (axios.isAxiosError(error) && error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
        
        // Handle validation errors
        if (error.response.status === 400 && error.response.data.validationErrors) {
          setValidationErrors(error.response.data.validationErrors);
        }
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col">
      <MerchantHubHeader />
      <main className="flex-1 flex min-h-screen font-subhead flex-row">
        <SideNavPost selected="Review my post" />
        <div className="flex flex-row p-8 w-[85vw]">
          <form className="flex flex-col font-subhead w-full mb-20">
            <div className="flex flex-col pt-0 pb-3 w-full font-subhead">
              <div className="flex flex-row justify-start mq780:hidden">
                <ChevronLeft onClick={onGoBackClick} className="cursor-pointer w-6 h-6 pt-2.5 pr-3 stroke-black" />
                <p className="bg-transparent mt-3 font-semibold">Upload Photos</p>
              </div>
            </div>
            <div className="self-stretch m-0 mb-4 text-19xl mq780:text-29xl font-semibold tracking-tight leading-[50.04px] text-zinc-700 max-md:max-w-full">
              Review Post
            </div>
            {offerData && (
              <ReviewPost 
                title={offerData.offer.name}
                price={offerData.offer.price}
                address={offerData.offer.locations?.[0]?.address}
                description={offerData.offer.description}
                knowbefore={offerData.offer.additionalInfo}
                images={offerId 
                  ? images
                  : JSON.parse(localStorage.getItem('uploadedImages') || '[]')}
                promotion={promotionData ? {
                  type: promotionData.type,
                  discountValue: promotionData.discountValue,
                  minimumPurchaseAmount: promotionData.minimumPurchaseAmount
                } : undefined}
              />
            )}
            <div className="flex justify-end mt-10 w-inherit">
              <button
                onClick={handleSubmit}
                disabled={isSubmitting}
                className={`w-[110px] p-2 py-3 text-base font-medium tracking-tight text-center text-white rounded-lg ${
                  isSubmitting 
                    ? 'bg-blue-300 cursor-not-allowed' 
                    : 'bg-blue-900 hover:bg-blue-800 cursor-pointer'
                }`}
              >
                {isSubmitting
                  ? offerId ? "Updating Deal..." : "Creating Deal..."
                  : offerId ? "Update Deal" : "Create Deal"}
              </button>
            </div>
            {(Object.entries(validationErrors).length > 0 || !offerData) && (
              <div className="mt-4 text-red-500">
                <h3 className="font-semibold">Validation Errors:</h3>
                <ul>
                  {!offerData && (
                    <li>No offer data available. Please fill in the offer details.</li>
                  )}
                  {Object.entries(validationErrors).map(([field, error]) => (
                    <li key={field}>{`${field}: ${error}`}</li>
                  ))}
                </ul>
              </div>
            )}
          </form>
          <div className="hidden mq1050:flex flex-col grow h-auto">
          <CompanyCard />
          <div className=""/>
          </div>
        </div>
      </main>
      <footer className="mt-auto">
        <Footer
          dYWLogoW1="/dyw-logow-1@2x.png"
          propAlignSelf="stretch"
          propPosition="unset"
          propTop="unset"
          propLeft="unset"
          propWidth="unset"
        />
      </footer>
    </div>
  );
};

export default MerchantHubPost3;